import { FirebaseOptions } from 'firebase/app';

import { AppCheckInstances } from '@angular/fire/app-check';
import { ɵAngularFireSchedulers } from '@angular/fire';
import { FIREBASE_APP_NAME, FIREBASE_OPTIONS } from '@angular/fire/compat';
import { AngularFireDatabase, AngularFireObject, URL } from '@angular/fire/compat/database';
import {
  AngularFireAuth,
  LANGUAGE_CODE,
  PERSISTENCE,
  SETTINGS,
  TENANT_ID,
  USE_DEVICE_LANGUAGE,
  USE_EMULATOR,
  USE_EMULATOR as USE_AUTH_EMULATOR,
} from '@angular/fire/compat/auth';
import { Inject, Injectable, NgZone, Optional, PLATFORM_ID } from '@angular/core';
import { AngularFireList, FirebaseOperation, PathReference, QueryFn } from '@angular/fire/compat/database/interfaces';

import { Store } from '@ngxs/store';

import { AuditLogService } from '@shared/services/audit-log.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
  deps: [FIREBASE_OPTIONS, [new Optional(), FIREBASE_APP_NAME], [new Optional(), URL], PLATFORM_ID, NgZone],
})
export class DatabaseWrapper extends AngularFireDatabase {
  constructor(
    private store: Store,
    private al: AuditLogService,
    @Inject(FIREBASE_OPTIONS) options: FirebaseOptions,
    @Optional() @Inject(FIREBASE_APP_NAME) name: string | null | undefined,
    @Optional() @Inject(URL) databaseURL: string | null,
    // tslint:disable-next-line:ban-types
    @Inject(PLATFORM_ID) platformId: Object,
    zone: NgZone,
    schedulers: ɵAngularFireSchedulers,
    @Optional() @Inject(USE_EMULATOR) _useEmulator: any, // tuple isn't working here
    @Optional() auth: AngularFireAuth,
    @Optional() @Inject(USE_AUTH_EMULATOR) useAuthEmulator: any,
    @Optional() @Inject(SETTINGS) authSettings: any, // can't use firebase.auth.AuthSettings here
    @Optional() @Inject(TENANT_ID) tenantId: string | null,
    @Optional() @Inject(LANGUAGE_CODE) languageCode: string | null,
    @Optional() @Inject(USE_DEVICE_LANGUAGE) useDeviceLanguage: boolean | null,
    @Optional() @Inject(PERSISTENCE) persistence: string | null,
    @Optional() _appCheckInstances: AppCheckInstances,
  ) {
    super(
      options,
      name,
      databaseURL,
      platformId,
      zone,
      schedulers,
      _useEmulator,
      auth,
      useAuthEmulator,
      authSettings,
      tenantId,
      languageCode,
      useDeviceLanguage,
      persistence,
      _appCheckInstances,
    );
  }

  list<T>(pathOrRef: PathReference, queryFn?: QueryFn, teamKey?: string, fromConsole?: boolean): AngularFireList<T> {
    const list = super.list<T>(pathOrRef, queryFn);

    if (!teamKey && !this.shouldLogAccess()) {
      return list;
    }

    const path = pathOrRef.toString();
    const { valueChanges, snapshotChanges, update, set, push, remove } = list;

    list.valueChanges = (...args) =>
      this.al.logDataAccess<Observable<T[]>>(valueChanges(...args), {
        access: 'READ',
        path,
        teamKey,
        fromConsole,
      }) as any;
    list.snapshotChanges = (...args) =>
      this.al.logDataAccess(snapshotChanges(...args), { access: 'READ', path, teamKey, fromConsole });
    list.update = (subPath: FirebaseOperation, data: Partial<T>) =>
      this.al.logDataAccess(update(subPath, data), {
        access: 'UPDATE',
        path: `${path}/${subPath.toString()}`,
        teamKey,
        fromConsole,
      });
    list.set = (subPath: FirebaseOperation, data: T) =>
      this.al.logDataAccess(set(subPath, data), {
        access: 'SET',
        path: `${path}/${subPath.toString()}`,
        teamKey,
        fromConsole,
      });
    list.remove = (subPath: FirebaseOperation) =>
      this.al.logDataAccess(remove(subPath), {
        access: 'REMOVE',
        path: `${path}/${subPath.toString()}`,
        teamKey,
        fromConsole,
      });
    list.push = (data: T) => {
      const pushRef = push(data);
      this.al.logDataAccess(
        pushRef.then(() => void 0),
        { access: 'PUSH', path, teamKey, fromConsole },
      );
      return pushRef;
    };

    return list;
  }

  object<T>(pathOrRef: PathReference, teamKey?: string, fromConsole?: boolean): AngularFireObject<T> {
    const object = super.object<T>(pathOrRef);

    if (pathOrRef.toString().startsWith('/studio') || (!teamKey && !this.shouldLogAccess())) {
      return object;
    }

    const path = pathOrRef.toString();
    const { valueChanges, snapshotChanges, update, set, remove } = object;

    object.valueChanges = () => this.al.logDataAccess(valueChanges(), { access: 'READ', path, teamKey, fromConsole });
    object.snapshotChanges = () =>
      this.al.logDataAccess(snapshotChanges(), { access: 'READ', path, teamKey, fromConsole });
    object.update = (data: Partial<T>) =>
      this.al.logDataAccess(update(data), { access: 'UPDATE', path, teamKey, data, fromConsole });
    object.set = (data: T) =>
      this.al.logDataAccess(set(data), { access: 'SET', path, teamKey, data: data as any, fromConsole });
    object.remove = () => this.al.logDataAccess(remove(), { access: 'REMOVE', path, teamKey, fromConsole });

    return object;
  }

  private shouldLogAccess(): boolean {
    return this.store.selectSnapshot((state) => !!state?.account?.team?.adminSettings?.auditLogging);
  }
}
