import { fromEvent, of, race, timer, Observable } from 'rxjs';
import { filter, mapTo, take, takeUntil, switchMap } from 'rxjs/operators';

import {
  MatLegacySnackBarRef as MatSnackBarRef,
  MAT_LEGACY_SNACK_BAR_DATA as MAT_SNACK_BAR_DATA,
} from '@angular/material/legacy-snack-bar';
import { Component, OnInit, Inject, ElementRef, ChangeDetectionStrategy } from '@angular/core';

import { LifecycleHooks } from '@shared/services/lifecycle-hooks.service';
import { SnackbarOptions } from '@shared/models/notice.models';

@Component({
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss'],
  providers: [LifecycleHooks],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Snackbar implements OnInit {
  readonly options: SnackbarOptions = {
    color: 'primary',
    ...this.data.options,
  };

  get parentNode(): Element {
    return this.elRef.nativeElement.parentNode;
  }

  constructor(
    @Inject(MAT_SNACK_BAR_DATA)
    public data: { options: SnackbarOptions; title: string },
    private snackBarRef: MatSnackBarRef<Snackbar>,
    private elRef: ElementRef,
    private lh: LifecycleHooks,
  ) {}

  ngOnInit(): void {
    this.raceWithEnter(this.options.timeout || 5000)
      .pipe(
        take(1),
        switchMap((close) =>
          close ? of(true) : fromEvent(this.parentNode, 'mouseleave').pipe(switchMap(() => this.raceWithEnter(3000))),
        ),
        filter((close) => close),
        take(1),
        takeUntil(this.lh.destroy),
      )
      .subscribe(() => this.dismiss());
  }

  onAction(): void {
    this.options.actionCallback?.();
    this.snackBarRef.dismissWithAction();
  }

  dismiss(): void {
    this.snackBarRef.dismiss();
    this.options.actionCallback = null;
  }

  private raceWithEnter(timeout: number): Observable<boolean> {
    return race(timer(timeout).pipe(mapTo(true)), fromEvent(this.parentNode, 'mouseenter').pipe(mapTo(false)));
  }
}
