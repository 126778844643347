import firebase from 'firebase/compat/app';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AngularFireAction, SnapshotAction } from '@angular/fire/compat/database';

declare type DataSnapshot = firebase.database.DataSnapshot;

export function mapListKeys<T>(name: string = '$key') {
  return (source: Observable<SnapshotAction<T>[]>) =>
    source.pipe(
      map((list) =>
        list
          ? list.map((object: AngularFireAction<DataSnapshot>) => ({
              [name]: object.key,
              ...(object.payload.val() as T),
            }))
          : null,
      ),
    );
}
