import { Observable, of } from 'rxjs';
import { catchError, shareReplay, switchMap } from 'rxjs/operators';

import { Injectable } from '@angular/core';

import { Store } from '@ngxs/store';
import { ActionDef } from '@ngxs/store/src/actions/symbols';

import { SourceType } from '@shared/models/utility.model';

export interface SourceData<T = any> {
  action: ActionDef;
  actionArgs?: any[];
  storeSelector: (key?: string) => any;
  obs: Record<string, Observable<T>>;
  defaultKey?: string | (() => any);
}

@Injectable({
  providedIn: 'root',
})
export class SourceTypeService {
  readonly keyMap: Partial<Record<SourceType, SourceData>> = {};

  constructor(private store: Store) {}

  registerSourceType(type: SourceType, data: SourceData): void {
    this.keyMap[type] = data;
  }

  getItem<T>(type: SourceType, key?: string): Observable<T | null> {
    const source = this.keyMap[type];

    if (!source) {
      return of(null);
    }

    if (key == null && source.defaultKey != null) {
      key =
        typeof source.defaultKey === 'function' ? this.store.selectSnapshot(source.defaultKey()) : source.defaultKey;
    }

    if (key == null) {
      key = '__UNKNOWN__';
    }

    if (!key) {
      return of(null);
    }

    const cachedObs = source.obs[key];

    if (!cachedObs) {
      if (key === '__UNKNOWN__') {
        source.obs[key] = of(null);
      } else {
        const selector = source.storeSelector(key);

        try {
          source.obs[key] = of(selector).pipe(
            switchMap(() => this.store.selectOnce<T>(selector)),
            catchError(() => of(null)),
            switchMap((value) => {
              if (value != null) {
                return of(value);
              }

              const args = (source.actionArgs || []).map((arg) => (arg === '{$key}' ? key : arg));

              return this.store.dispatch(new source.action(...args));
            }),
            switchMap(() => this.store.select<T>(selector)),
            catchError(() => of(null)),
            shareReplay(1),
          );
        } catch {
          source.obs[key] = of(null);
        }
      }
    }

    return source.obs[key];
  }
}
