import { NgZone } from '@angular/core';
import { from, Observable } from 'rxjs';

/**
 * Returns an `Observable<T>` from `Promise<T>` that runs outside Angular zone
 *
 * @usageNotes
 *  ### Example
 *
 *  ```
 *  public getAuthToken() {
 *    const toPromise = () =>
 *      this.currentUser.getIdToken();
 *
 *    return runOutsideZone(this.zone)(toPromise);
 *  }
 * ```
 */
export function runOutsideZone(zone: NgZone) {
  return <T>(toPromise: (...args: any[]) => Promise<T>) =>
    new Observable<T>((subscriber) =>
      zone.runOutsideAngular(() =>
        from(toPromise()).subscribe(
          (value) =>
            zone.run(() => {
              subscriber.next(value);
              subscriber.complete();
            }),

          (error) =>
            zone.run(() => {
              subscriber.error(error);
              subscriber.complete();
            }),
        ),
      ),
    );
}
