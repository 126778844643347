import { LicenseFeature } from '@shared/enums/license-feature.enum';

export type FeatureValue = boolean | any;

export interface FeatureData {
  key: TeamFeature | UserFeature;
  title?: string;
  description?: string;
  value?: FeatureValue;
  licenseFeature?: LicenseFeature;
}

export enum TeamFeature {
  ENABLE_EXPERIMENTAL = 'enable_experimental',
  ALL_FEATURES = 'all_features',
  AI_INTERVIEWER = 'ai_interviewer',
  SURVEY_MAKER_AI = 'survey_maker_ai',
  INTEGRATIONS = 'integrations',
  INSIGHTS = 'insights',
  BENCHMARK_DATA = 'use_template_answer_comparison',
  BENCHMARK_DATA_FOR_CHILDREN = 'use_template_answer_comparison_for_children',
}

export const BetaFeatures: TeamFeature[] = [
  TeamFeature.AI_INTERVIEWER,
  TeamFeature.SURVEY_MAKER_AI,
  TeamFeature.INTEGRATIONS,
  TeamFeature.INSIGHTS,
];

export enum UserFeature {}
