import { FirebaseOptions } from 'firebase/app';

import { Inject, Injectable, NgZone, Optional, PLATFORM_ID } from '@angular/core';

import { FIREBASE_APP_NAME, FIREBASE_OPTIONS } from '@angular/fire/compat';

import {
  AngularFireAuth,
  LANGUAGE_CODE,
  PERSISTENCE,
  SETTINGS,
  TENANT_ID,
  USE_DEVICE_LANGUAGE,
  USE_EMULATOR,
} from '@angular/fire/compat/auth';
import { ɵAngularFireSchedulers } from '@angular/fire';
import { AppCheckInstances } from '@angular/fire/app-check';

@Injectable({
  providedIn: 'root',
  deps: [FIREBASE_OPTIONS, [new Optional(), FIREBASE_APP_NAME], PLATFORM_ID, NgZone],
})
export class AuthWrapper extends AngularFireAuth {
  constructor(
    @Inject(FIREBASE_OPTIONS) options: FirebaseOptions,
    @Optional() @Inject(FIREBASE_APP_NAME) name: string | null | undefined,
    // tslint:disable-next-line:ban-types
    @Inject(PLATFORM_ID) platformId: Object,
    zone: NgZone,
    schedulers: ɵAngularFireSchedulers,
    @Optional() @Inject(USE_EMULATOR) useEmulator: any, // can't use the tuple here
    @Optional() @Inject(SETTINGS) settings: any, // can't use firebase.auth.AuthSettings here
    @Optional() @Inject(TENANT_ID) tenantId: string | null,
    @Optional() @Inject(LANGUAGE_CODE) languageCode: string | null,
    @Optional() @Inject(USE_DEVICE_LANGUAGE) useDeviceLanguage: boolean | null,
    @Optional() @Inject(PERSISTENCE) persistence: string | null,
    @Optional() _appCheckInstances: AppCheckInstances,
  ) {
    super(
      options,
      name,
      platformId,
      zone,
      schedulers,
      useEmulator,
      settings,
      tenantId,
      languageCode,
      useDeviceLanguage,
      persistence,
      _appCheckInstances,
    );
  }
}
