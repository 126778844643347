/**
 * Manages rights data of the user.
 *
 * @unstable
 */

import { Injectable } from '@angular/core';

import { Store } from '@ngxs/store';

import { AccountState } from '@shared/states/account.state';

import { DatabaseWrapper } from '@shared/services/database-wrapper.service';
import { Rights } from '@shared/enums/rights.enum';

@Injectable({
  providedIn: 'root',
})
export class RightsManager {
  constructor(
    private db: DatabaseWrapper,
    private store: Store,
  ) {}

  setTeamRights(userEmailKey: string, rights: number, team?: string): Promise<void> {
    const activeUserKey = this.store.selectSnapshot(AccountState.userKey);
    const activeTeamKey = this.store.selectSnapshot(AccountState.teamKey);

    const teamKey = team || activeTeamKey;

    if (userEmailKey.indexOf('@') === -1) {
      return this.db
        .object(`/teams/${teamKey}/users/${userEmailKey}`)
        .set(rights)
        .then(() => {
          if (activeUserKey === userEmailKey && teamKey === activeTeamKey) {
            window.location.reload();
          }
        });
    } else {
      if (rights) {
        return this.db.object(`/teams/${teamKey}/invited/${userEmailKey}`).set(rights);
      } else {
        return this.db.object(`/teams/${teamKey}/invited/${userEmailKey}`).remove();
      }
    }
  }

  setSurveyRights(surveyKey: string, userKey: string, rights: number): Promise<void> {
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);

    return this.db.object(`/surveys/${teamKey}/${surveyKey}/users/${userKey}`).set(rights);
  }

  setMultipleSurveyRights(surveyKeys: string[], userKey: string, rights: Rights): Promise<void> {
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);

    const data = surveyKeys.reduce((dict, surveyKey) => ({ ...dict, [`/${surveyKey}/users/${userKey}`]: rights }), {});

    return this.db.object(`/surveys/${teamKey}`).update(data);
  }
}
