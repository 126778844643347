import { Observable, timer, of, Subject } from 'rxjs';
import { takeUntil, concatMap, filter } from 'rxjs/operators';

import { Action } from '@ngxs/store';
import { ActionDef } from '@ngxs/store/src/actions/symbols';

import { KeyOf } from '@shared/utilities/typescript.utilities';

export interface DebounceActionOptions<T extends object = any> {
  debounceTime: number;
  ignoreProperties?: KeyOf<T>[];
}

export function DebounceAction<T extends ActionDef>(
  actions: T | T[],
  options: DebounceActionOptions<InstanceType<T>> | number,
) {
  const realOptions = (typeof options === 'number' ? { debounceTime: options } : options) as DebounceActionOptions;

  return (target: any, name: string, descriptor: TypedPropertyDescriptor<any>) => {
    Action(actions)(target, name, descriptor);

    const originalMethod: Function = descriptor.value;
    const debounce$ = new Subject<string>();

    descriptor.value = function (ctx, action) {
      const argsAction = { ...action };

      (realOptions.ignoreProperties || []).forEach((prop) => delete argsAction[prop]);

      const args = JSON.stringify(argsAction);

      debounce$.next(args);

      return timer(realOptions.debounceTime).pipe(
        concatMap(() => {
          const obs = originalMethod.apply(this, [ctx, action]);

          return obs instanceof Observable || obs instanceof Promise ? obs : of(obs);
        }),
        takeUntil(debounce$.pipe(filter((debounceArgs) => debounceArgs === args))),
      );
    };

    return descriptor;
  };
}
