import { MessageType } from '@shared/models/email.model';

export interface SearchParams {
  rows: number;
  start: number;
  query: string;
  sortBy: string;
  sortDirection: 'ASC' | 'DESC';
}

export interface UserSearchParams extends SearchParams {
  withSurvey?: string | null;
  withoutSurvey?: string | null;
}

export interface SurveySearchParams extends SearchParams {
  showAll: boolean;
}

export interface TeamSearchParams extends SearchParams {
  showAll: boolean;
}

export interface EmailSearchParams extends SearchParams {
  excludeTypes: MessageType[] | string;
  excludeEmail?: string;
  surveyKey?: string;
}

export interface SearchResult<T extends SearchRecord> {
  result: T[];
  totalCount: number;
  count: number;
}

export interface SearchRecord {
  key: string;
}

export interface TeamRecord extends SearchRecord {
  name: string;
  logo: string;
  plan: string;

  active: number;

  created: string;
  owner: string;

  members: number;
  invites: number;
}

export interface ConsoleTeamRecord extends TeamRecord {
  pulse: number;
  pulseChange: number;
  idleMonths: number;
}

export interface UserRecord extends SearchRecord {
  team: string;

  role?: number;
  rights: number;

  name: string;
  email: string;
  avatar: string;

  owner?: string[];
  editor?: string[];
  viewer?: string[];

  invite: string | null;
}

export interface SurveyRecord extends SearchRecord {
  name: string;
  image: string;
  owner: string;
}

export interface EmailRecord extends SearchRecord {
  anonymous: boolean;
  created: number;
  creator: string;
  name: string;
  parent?: string;
  sending?: number;
  status: 'new' | 'sending' | 'sent' | 'draft';
  subject: string;
  surveyKey: string;
}

export enum SearchTeamDataPath {
  Users = 'users',
  Emails = 'emails',
  Teams = 'teams',
}

export enum SearchUserDataPath {
  Teams = 'userTeams',
  Surveys = 'userSurveys',
}

export type SearchPath = SearchTeamDataPath | SearchUserDataPath;

export interface PreviousEmailParams {
  email?: string;
  limit: number;
  query: string;
  reminders?: boolean;
  surveyKey: string;
  emailKey?: string;
}
