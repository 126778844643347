/**
 * Service for sending lifecycle events.
 *
 * @unstable
 */

import { Subject, Observable } from 'rxjs';

import { Injectable, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';

@Injectable()
export class LifecycleHooks implements OnDestroy {
  readonly create: Subject<void> = new Subject<void>();
  readonly destroy: Subject<void> = new Subject<void>();

  private destroyed?: boolean;

  readonly untilDestroy = (source: Observable<any>) => source.pipe(takeUntil(this.destroy));

  constructor() {
    this.create.next();
    this.create.complete();
  }

  isDestroyed(): boolean {
    return this.destroyed;
  }

  ngOnDestroy(): void {
    this.destroyed = true;
    this.destroy.next();
    this.destroy.complete();
  }
}
