import { BehaviorSubject, combineLatest, Observable, of, ReplaySubject } from 'rxjs';

import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { SourceType } from '@shared/models/utility.model';
import { SourceTypeService } from '@shared/services/source-type.service';
import { map, shareReplay, switchMap } from 'rxjs/operators';
import { assertArray } from '@shared/utilities/array.utilities';
import { teamLogoPlaceholder } from '@shared/utilities/assets.utilities';

interface SourceSetting {
  icon?: string;
  property?: string | string[];
  color?: string;
  defaultSrc?: string | ((...args: any[]) => string);
  useService?: boolean;
}

@Component({
  selector: 'zef-source-logo',
  templateUrl: './source-logo.component.html',
  styleUrls: ['./source-logo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SourceLogo implements OnChanges {
  @Input()
  source?: SourceType;

  @Input()
  key?: string;

  @Input()
  color?: string;

  @Input()
  size: 'mini' | 'tiny' | 'small' | 'medium' | 'large' = 'tiny';

  @Input()
  shape: 'square' | 'rect' = 'square';

  iconSize: 'small' | 'medium' | 'large' | 'larger' | 'largest' | 'huge' = 'large';

  private sizeIconMap: Record<SourceLogo['size'], SourceLogo['iconSize']> = {
    mini: 'medium',
    tiny: 'large',
    small: 'larger',
    medium: 'largest',
    large: 'huge',
  };

  private readonly key$ = new BehaviorSubject<string | void>(void 0);

  private readonly source$ = new ReplaySubject<SourceType>(1);

  private readonly sourceSettings: Partial<Record<SourceType, SourceSetting>> = {
    'list': { icon: 'list' },
    'import': { icon: 'upload' },
    'copy-paste': { icon: 'action_copy' },
    'integration': { icon: 'type', property: 'type', color: 'type', useService: true },
    'survey': {
      property: ['background.url', 'logo.url'],
      defaultSrc: 'assets/images/survey-placeholder.png',
      useService: true,
    },
    'team': {
      property: 'logo',
      defaultSrc: (team: { created?: number }) => teamLogoPlaceholder(team?.created),
      useService: true,
    },
    'consoleTeam': {
      property: 'logo',
      defaultSrc: (team: { created?: number }) => teamLogoPlaceholder(team?.created),
      useService: true,
    },
    'parentTeam': {
      property: 'logo',
      defaultSrc: (team: { created?: number }) => teamLogoPlaceholder(team?.created),
      useService: true,
    },
    'user': { property: 'avatar', defaultSrc: 'assets/images/user-placeholder.png', useService: true },
  };

  readonly sourceItem$: Observable<{ icon: boolean; src: string; color?: string } | undefined> = combineLatest([
    this.key$,
    this.source$,
  ]).pipe(
    switchMap(([key, source]) => {
      const settings = this.sourceSettings[source];

      if (settings?.useService) {
        const realSource = source === 'survey' ? 'surveyDesign' : source;

        return this.st.getItem<object>(realSource, key as string).pipe(
          map((item) => ({
            icon: !!settings.icon,
            src: this.getSourceFromItem(item, settings),
            color: item?.[settings.color],
          })),
        );
      } else if (settings) {
        return of({ icon: true, src: settings.icon || 'map_broken', color: this.color });
      }
    }),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  constructor(private st: SourceTypeService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.source) {
      this.source$.next(this.source);
    }

    if (changes.key) {
      this.key$.next(this.key);
    }

    if (changes.size) {
      this.iconSize = this.sizeIconMap[this.size];
    }
  }

  private getSourceFromItem(item: object, settings: SourceSetting): string {
    return (
      assertArray(settings.property)
        .map((property) =>
          property
            .split('.')
            .reduce((a, b) => a?.[b], item)
            ?.toString(),
        )
        .find((property) => !!property) ||
      (settings.defaultSrc &&
        (typeof settings.defaultSrc === 'string' ? settings.defaultSrc : settings.defaultSrc(item))) ||
      item.toString()
    );
  }
}
