export interface EditorPrefs {
  hideArchiveWarningDialog?: boolean;
  hideAdminTools?: boolean;
  hideAiInterviewBanner?: boolean;
}

export enum EditorTab {
  Build = 'build',
  Design = 'design',
  Setup = 'settings',
}
