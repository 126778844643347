export enum ViewSize {
  Tiny = 'tn',
  ExtraSmall = 'xs',
  Small = 'sm',
  Medium = 'md',
  Large = 'lg',
  ExtraLarge = 'xl',
  NotAvailable = 'na',
}

/* eslint-disable @typescript-eslint/no-namespace */
export namespace ViewSize {
  export const RealSizes = [
    ViewSize.Tiny,
    ViewSize.ExtraSmall,
    ViewSize.Small,
    ViewSize.Medium,
    ViewSize.Large,
    ViewSize.ExtraLarge,
  ];

  const Mobile = [ViewSize.Tiny, ViewSize.ExtraSmall, ViewSize.Small];

  const Desktop = [ViewSize.Large, ViewSize.ExtraLarge];

  export const isMobile = (size: ViewSize) => Mobile.includes(size);

  export const isDesktop = (size: ViewSize) => Desktop.includes(size);
}
