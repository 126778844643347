import { OutcomeOptionsScore, Outcomes } from '@shared/enums/outcomes.enum';
import { Questions } from '@shared/enums/questions.enum';
import {
  BuilderData,
  BuilderType,
  ChoiceItemData,
  CreateOptions,
  DefaultReportSettings,
  DesignData,
  OutcomeData,
  QuestionActionData,
  QuestionData,
  ScoringData,
  SurveyData,
  SurveySetting,
  TriggerData,
} from '@shared/models/survey.model';
import { SlashedObjectPath } from '@shared/utilities/typescript.utilities';
import { SurveyShareLink } from '@shared/models/survey-shares.model';

export class GetSurvey {
  static readonly type = '[Survey] Get Survey';

  constructor(readonly surveyKey: string | null) {}
}

export class GetSurveyHistory {
  static readonly type = '[Survey] Get Survey History';

  constructor(readonly surveyKey: string | null) {}
}

export class GetSurveyOwnerHistory {
  static readonly type = '[Survey] Get Survey Owner History';

  constructor(readonly surveyKey: string | null) {}
}

export class GetSurveyQuestionHistory {
  static readonly type = '[Survey] Get Survey Owner History';

  constructor(readonly surveyKey: string | null) {}
}

export class GetDesign {
  static readonly type = '[Survey] Get Design';

  constructor(readonly surveyKey: string | null) {}
}

export class GetLocales {
  static readonly type = '[Survey] Get Locales';

  constructor(readonly surveyKey: string | null) {}
}

export class GetSharing {
  static readonly type = '[Survey] Get Sharing';

  constructor(readonly surveyKey: string | null) {}
}

export class GetRelease {
  static readonly type = '[Survey] Get Release';

  constructor(readonly surveyKey: string | null) {}
}

export class GetScoring {
  static readonly type = '[Survey] Get Scoring';

  constructor(readonly surveyKey: string | null) {}
}

export class GetOutcomes {
  static readonly type = '[Survey] Get Outcomes';

  constructor(readonly surveyKey: string | null) {}
}

export class GetQuestions {
  static readonly type = '[Survey] Get Questions';

  constructor(readonly surveyKey: string | null) {}
}

export class GetTriggers {
  static readonly type = '[Survey] Get Triggers';

  constructor(readonly surveyKey: string | null) {}
}

export class GetUsage {
  static readonly type = '[Survey] Get Usage';

  constructor(readonly surveyKey: string | null) {}
}

export class GetOwner {
  static readonly type = '[Survey] Get Owner';

  constructor(readonly surveyKey: string | null) {}
}

export class GetRights {
  static readonly type = '[Survey] Get Rights';

  constructor(readonly surveyKey: string | null) {}
}

export class GetRespondents {
  static readonly type = '[Survey] Get Respondents';

  constructor(readonly surveyKey: string | null) {}
}

export class GetInvites {
  static readonly type = '[Survey] Get Invites';

  constructor(readonly surveyKey: string | null) {}
}

export class CreateSurvey {
  static readonly type = '[Survey] Create Survey';

  constructor(readonly options: Partial<CreateOptions>) {}
}

export class UpdateSurveyData {
  static readonly type = '[Survey] Update survey data';

  constructor(
    readonly surveyKey?: string,
    readonly data: Partial<SurveyData> = {},
  ) {}
}

export class UpdateDefaultReportSettingsData {
  static readonly type = '[Survey] Update default report settings';

  constructor(
    readonly data: Partial<DefaultReportSettings> = {},
    readonly surveyKey?: string,
  ) {}
}

export class UpdateSettings {
  static readonly type = '[Survey] - Update Settings';
  constructor(
    readonly settingsList: SurveySetting[],
    readonly remove = false,
  ) {}
}

export class SaveSurveyShareLinkTemplate {
  static type = '[SurveyShares] - Save survey shares link template';

  constructor(
    readonly shareLink: SurveyShareLink,
    readonly remove = false,
  ) {}
}

export class UpdateDesign {
  static readonly type = '[Survey] Update design';

  constructor(
    readonly update: Partial<{ [path in SlashedObjectPath<DesignData>]: any }>,
    readonly surveyKey?: string,
  ) {}
}

export class ToggleWelcome {
  static readonly type = '[Survey] Toggle Welcome';

  constructor(
    readonly surveyKey: string,
    readonly toggle: boolean,
    readonly data?: Pick<SurveyData, 'title'>,
    readonly language?: string,
  ) {}
}

export class TogglePolicy {
  static readonly type = '[Survey] Toggle Policy';

  constructor(
    readonly surveyKey: string,
    readonly policy: boolean,
  ) {}
}

export class ToggleResultType {
  static readonly type = '[Survey] Toggle Result Type';

  constructor(
    readonly surveyKey: string,
    readonly type: Outcomes,
  ) {}
}

export class AddQuestion {
  static readonly type = '[Survey] Add Question';

  constructor(
    readonly question: Partial<QuestionData>,
    readonly index?: number,
    readonly language?: string,
  ) {}
}

export class AddGroup {
  static readonly type = '[Survey] Add Group';

  constructor(readonly index?: number) {}
}

export class DeleteQuestion {
  static readonly type = '[Survey] Delete Question';

  constructor(
    readonly question: QuestionData,
    readonly force?: boolean,
  ) {}
}

export class UpdateQuestion {
  static readonly type = '[Survey] Update Question';

  constructor(
    readonly questionKey: string,
    readonly data: Partial<QuestionData>,
    readonly force?: boolean,
  ) {}
}

export class CopyQuestionSettings {
  static readonly type = '[Survey] Copy Question Settings';

  constructor(
    readonly source: string,
    readonly target: string,
    readonly force?: boolean,
  ) {}
}

export class UpdateGroup {
  static readonly type = '[Survey] Update Group';

  constructor(
    readonly groupKey: string,
    readonly data: Partial<QuestionData>,
    readonly force?: boolean,
    public skipType?: Questions,
  ) {}
}

export class MoveQuestion {
  static readonly type = '[Survey] Move Question';

  constructor(
    readonly question: QuestionData,
    readonly index?: number,
    readonly group?: string,
    readonly force?: boolean,
  ) {}
}

export class MoveOutcome {
  static readonly type = '[Survey] Move Outcome';

  constructor(
    readonly outcome: OutcomeData,
    readonly index?: number,
  ) {}
}

export class RestoreQuestion {
  static readonly type = '[Survey] Restore Question';

  constructor(readonly question: QuestionData) {}
}

export class RestoreGroup {
  static readonly type = '[Survey] Restore Group';

  constructor(readonly keys: string[]) {}
}

export class DuplicateQuestion {
  static readonly type = '[Survey] Duplicate Question';

  constructor(readonly question: QuestionData) {}
}

export class ReplaceQuestion {
  static readonly type = '[Survey] Replace Question';

  constructor(
    readonly source: string,
    readonly replacement: QuestionData,
    readonly forceAction: true | any,
  ) {}
}

export class ArchiveQuestion {
  static readonly type = '[Survey] Archive Question';

  constructor(
    readonly data: QuestionData,
    readonly restore = false,
  ) {}
}

export class RemoveArchivedLogic {
  static readonly type = '[Survey] Remove Archived Logic';

  constructor(
    readonly data: BuilderData,
    readonly type: BuilderType,
  ) {}
}

export class AddSampleGroup {
  static readonly type = '[Survey] Add Sample Group';

  constructor(
    readonly questions: QuestionData[],
    readonly language: string,
  ) {}
}

export class AddOutcome {
  static readonly type = '[Survey] Add Result';

  constructor(
    readonly type: Outcomes = Outcomes.OUTCOME,
    readonly index?: number,
  ) {}
}

export class UpdateOutcome {
  static readonly type = '[Survey] Update Outcome';

  constructor(
    readonly resultKey: string,
    readonly data: Partial<OutcomeData>,
  ) {}
}

export class DuplicateOutcome {
  static readonly type = '[Survey] Duplicate Outcome';

  constructor(readonly outcome: OutcomeData) {}
}

export class ArchiveOutcome {
  static readonly type = '[Survey] Archive Outcome';

  constructor(
    readonly data: OutcomeData,
    readonly restore = false,
  ) {}
}

export class ScoreOutcome {
  static readonly type = '[Survey] Score Outcome';

  constructor(
    readonly surveyKey: string,
    readonly outcomeKey: string,
    readonly questionKey: string,
    readonly data: ScoringData | null = null,
  ) {}
}

export class DeleteResult {
  static readonly type = '[Survey] Delete Result';

  constructor(readonly result: OutcomeData) {}
}

export class RestoreResult {
  static readonly type = '[Survey] Restore Result';

  constructor(readonly result: OutcomeData) {}
}

export class AddTrigger {
  static readonly type = '[Survey] Add Trigger';

  constructor(readonly index?: number) {}
}

export class DeleteTrigger {
  static readonly type = '[Survey] Delete Trigger';

  constructor(readonly trigger: TriggerData) {}
}

export class RestoreTrigger {
  static readonly type = '[Survey] Restore Trigger';

  constructor(readonly trigger: TriggerData) {}
}

export class UpdateTrigger {
  static readonly type = '[Survey] Update Trigger';

  constructor(
    readonly triggerKey: string,
    readonly data: Partial<TriggerData>,
  ) {}
}

export class ArchiveTrigger {
  static readonly type = '[Survey] Archive Trigger';

  constructor(
    readonly data: TriggerData,
    readonly restore = false,
  ) {}
}

export class UpdateSurveyOnline {
  static readonly type = '[Survey] Update Survey Online';

  constructor(readonly online: boolean) {}
}

export class UpdateSurveyClosing {
  static readonly type = '[Survey] Update Survey Closing';

  constructor(readonly closeAt: number | null) {}
}

export class QuestionActionDialog {
  static readonly type = '[Survey] Question action dialog';

  constructor(readonly data: QuestionActionData) {}
}

export class QuestionScoredDialog {
  static readonly type = '[Survey] Question scored dialog';

  constructor(
    readonly questions: QuestionData[],
    readonly group: QuestionData,
    readonly index?: number,
    readonly action?: MoveQuestion | UpdateGroup,
  ) {}
}

export class QuestionScoredProcess {
  static readonly type = '[Survey] Question scored process';

  constructor(
    readonly action: QuestionScoredDialog,
    readonly selected?: QuestionData,
    readonly force?: boolean,
  ) {}
}

export class NoticeRemove {
  static readonly type = '[Survey] Notice remove';

  constructor(
    readonly type: BuilderType,
    readonly restoreAction: RestoreGroup | RestoreQuestion | RestoreResult | RestoreTrigger,
  ) {}
}

export class NoticeCopyOptions {
  static readonly type = '[Survey] Notice copy options';

  constructor(readonly source: QuestionData) {}
}

export class DeleteOutcomeSharingImage {
  static readonly type = '[Survey] Delete Outcome Sharing Image';

  constructor(readonly surveyKey: string) {}
}

export class GetSurveys {
  static readonly type = '[Survey] Get Surveys';
}

export class ChangeOutcomeOptionsCount {
  static readonly type = '[Survey] Change Outcome Options Count';

  constructor(readonly count: number) {}
}

export class ChangeOutcomeOptionsScore {
  static readonly type = '[Survey] Change Outcome Options Score';

  constructor(readonly score: OutcomeOptionsScore) {}
}

export class ChangeOutcomeOptionsHideZeroScoreOutcomes {
  static readonly type = '[Survey] Change Outcome Options Hide Zero Score Outcomes';

  constructor(readonly hideZeroScoreOutcomes: boolean) {}
}

export class ResetOutcomeOptions {
  static readonly type = '[Survey] Reset Outcome Options';

  constructor(
    readonly count: number,
    readonly score: OutcomeOptionsScore,
    readonly hideZeroScoreOutcomes: boolean,
  ) {}
}

export class AddQuestionChoice {
  static readonly type = '[Survey] Add question choice';

  constructor(
    readonly question: string,
    readonly content?: {
      text: string;
      language: string;
    },
  ) {}
}

export class AddQuestionChoices {
  static readonly type = '[Survey] Add question choices';

  constructor(
    readonly question: string,
    readonly choices: string[],
    readonly language: string,
  ) {}
}

export class UpdateQuestionChoiceContent {
  static readonly type = '[Survey] Update question choice';

  constructor(
    readonly question: string,
    readonly choice: string,
    readonly content: string | { text: string; language: string },
  ) {}
}

export class UpdateQuestionChoiceData {
  static readonly type = '[Survey] Update question choice data';

  constructor(
    readonly question: string,
    readonly choice: string,
    readonly data: Partial<ChoiceItemData>,
  ) {}
}

export class DeleteQuestionChoice {
  static readonly type = '[Survey] Delete question choice';

  constructor(
    readonly question: string,
    readonly choice: string,
  ) {}
}

export class ToggleQuestionOther {
  static readonly type = '[Survey] Toggle question other';

  constructor(
    readonly question: string,
    readonly enabled: boolean,
  ) {}
}

export class MoveQuestionChoice {
  static readonly type = '[Survey] Move question choice';

  constructor(
    readonly question: string,
    readonly choice: string,
    readonly index: number,
  ) {}
}

export class SwitchQuestionChoice {
  static readonly type = '[Survey] Switch question choice';

  constructor(
    readonly question: string,
    readonly sourceChoice: string,
    readonly targetChoice: string,
  ) {}
}

export class PublishSurvey {
  static readonly type = '[Survey] Publish Survey';

  constructor(
    readonly surveyKey: string,
    readonly autoVersion: boolean,
  ) {}
}

export class DiscardDraft {
  static readonly type = '[Survey] Discard Draft';

  constructor(readonly surveyKey: string) {}
}

export class MigrateDefaultLocales {
  static readonly type = '[Survey] Migrate Default Locales';

  constructor(readonly surveyKey: string) {}
}

export class SetAnonymous {
  static readonly type = '[Survey] Set Anonymous';

  constructor(
    readonly surveyKey: string,
    readonly anonymous: number,
  ) {}
}
