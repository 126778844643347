import { Injectable } from '@angular/core';
import {
  MatLegacySnackBar as MatSnackBar,
  MatLegacySnackBarRef as MatSnackBarRef,
} from '@angular/material/legacy-snack-bar';

import { Snackbar } from '@shared/components/snackbar/snackbar.component';
import { SnackbarOptions } from '@shared/models/notice.models';

/**
 * Handles sending notice snackbars
 */
@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  constructor(private sb: MatSnackBar) {}

  /**
   * Opens a new snackbar. Optional action can be passed
   *
   * @param  title      Title of the action
   * @param  options    Options for the snackbar
   */
  public open(title: string, options: SnackbarOptions = {}): MatSnackBarRef<Snackbar> {
    return this.sb.openFromComponent(Snackbar, {
      verticalPosition: 'bottom',
      horizontalPosition: 'left',
      data: {
        options: {
          ...options,
          timeout: options.timeout || 5000,
          actionName: options.actionName || '',
          actionCallback: options.actionCallback || null,
          color: options.color || 'primary',
        },
        title,
      },
    });
  }
}
