<div
  *ngIf="options as options"
  class="snackbar z-fx-sc z-fx-gap-32 zef-bgcolor-ink zef-shadow-4 zef-text-overflow-line"
>
  <span class="zef-text-p1 z-fx-grow zef-text-ellipsis">
    <ng-container *ngIf="!options.noticeTemplate; else options.noticeTemplate">{{ data.title }}</ng-container>
  </span>

  <div class="z-fx z-fx-center-end z-fx-gap-4">
    <button *ngIf="options.actionName" mat-raised-button color="stealth" (click)="onAction()">
      {{ options.actionName }}
    </button>

    <button mat-icon-button color="stealth" (click)="dismiss()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="snackbar-status" [ngClass]="'zef-bgcolor-' + (options.color || 'primary')"></div>
</div>
