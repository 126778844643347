import { StateToken } from '@ngxs/store';
import { ViewSize } from '@shared/enums/view-size.enum';
import { ChatPrefs, ContactsPagePrefs, ImportPrefs } from '@shared/models/contact.model';
import { EditorPrefs } from '@shared/models/editor.model';
import { FeatureValue, UserFeature } from '@shared/models/features.model';
import { IdentityData, PropertyData, StatesData } from '@shared/models/prefs.model';
import { ExportSettings } from '@shared/models/report.model';
import { SurveySharesOrderState } from '@shared/models/survey-shares.model';
import { SurveySearchPrefs } from '@shared/models/survey.model';

export interface PrefsStateModel {
  country: string | null;
  language: string | null;
  locale: string | null;

  users: { [uid: string]: IdentityData };
  states: StatesData | null;

  identity: IdentityData | null;
  features?: Record<UserFeature, FeatureValue>;

  properties: PropertyData | null;
  surveySearch: SurveySearchPrefs | null;
  surveySharesOrder: Record<string, SurveySharesOrderState>;
  contactsPage: ContactsPagePrefs;
  editor?: EditorPrefs;
  import: ImportPrefs;
  chat: ChatPrefs;
  media: ViewSize | null;

  columns: { [table: string]: Record<string, any> };
  help: { [topic: string]: Record<string, any> };
  touchEnabled: boolean;
  scrollEnabled: boolean;

  reportExportSettings?: ExportSettings;
}

export const PREFS_STATE_TOKEN = new StateToken<PrefsStateModel>('prefs');
