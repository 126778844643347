import { AppProject, CloudLocation } from '@env/environment.models';

export const environment = {
  config: '',

  debug: true,

  production: false,

  firebase: {
    appId: 'zefApp',
    apiKey: 'AIzaSyBpXu4EnXxz6aVt15I49JvHJe4EAbLj-us',
    authDomain: `app.test.zeffi.dev`,
    databaseURL: `https://${AppProject.Testing}-db.europe-west1.firebasedatabase.app`,
  },

  templatesTeamKey: '----zeffi-public----',

  publicUrl: '//test.zeffi.dev',

  apiServer: '//api.test.zeffi.dev',
  bckServer: '//proxy.api.test.zeffi.dev',

  webAddress: '//app.test.zeffi.dev',
  wwwAddress: '//www.test.zeffi.dev',

  pollAddress: '//poll.test.zeffi.dev',
  surveyAddress: '//survey.test.zeffi.dev',
  reportAddress: '//report.test.zeffi.dev',
  storageAddress: '//storage.test.zeffi.dev',

  // cloudFunctions: 'https://app.test.zeffi.dev',
  cloudFunctions: `https://${CloudLocation.Europe}-${AppProject.Testing}.cloudfunctions.net`,

  activityServer: 'https://cases.zef.fi/zef/zef-platform/activity-log/',

  segmentClientKey: '',

  stripePublishableKey: 'pk_test_yieMgAEwuLiCqbwTHri3kmJr',

  googleBrowserKey: 'AIzaSyBpXu4EnXxz6aVt15I49JvHJe4EAbLj-us',

  googleClientId: '595229186990-ct8ogavj8o91eoccj25udhbsrt4i8e3a.apps.googleusercontent.com',

  hubspot: {
    accountId: '7195983',
    companyId: '0-2',
  },

  unsplash: {
    appId: 185789,
  },

  zendesk: {
    helpDeskApi: 'https://zefhelp.zendesk.com/api/v2/help_center/',
  },
};
