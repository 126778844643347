/*
 * Bypasses angular security checker for the given styles string.
 *
 * @unstable
 */

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'encodeURI',
})
export class EncodeURI implements PipeTransform {
  constructor() {}

  public transform(url: string, format?: string): any {
    if (format === 'url') {
      return 'url(' + encodeURI(url || '') + ')';
    } else {
      return encodeURI(url || '');
    }
  }
}
