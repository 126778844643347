/**
 * Loading button.
 *
 * See visual.scss
 *
 * @unstable
 */

import { Directive, HostBinding, Input } from '@angular/core';

@Directive({
  selector: '[mat-raised-button][loading],[mat-icon-button][loading]',
})
export class ButtonLoading {
  @Input()
  @HostBinding('class.loading')
  loading: boolean = false;

  @Input()
  disabled?: boolean;

  @HostBinding('disabled')
  get isDisabled(): boolean {
    return this.loading || this.disabled;
  }
}
