import { Params } from '@angular/router';

import { PrefsStateModel } from '@shared/states/prefs-state.models';

export function getPrefLanguage(prefState: PrefsStateModel, params: Params, buildLanguage = 'en'): string {
  const queryLanguage = (params?.lang || '').length === 2 ? params.lang : null;
  const stateLanguage = prefState.language;

  return queryLanguage || stateLanguage || buildLanguage || 'en';
}
