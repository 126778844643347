import { isDeepEqual } from '@shared/utilities/object.utilities';

export function random<T>(array: T[]): T {
  return array[Math.floor(Math.random() * array.length)];
}

export function range(length: number, start: number = 0) {
  return Array.from({ length }, (_, i) => i + start);
}

export function isArrayShallowEqual(
  a: any[],
  b: any[],
  compareFunc = (x, y) => x.every((child) => y.includes(child)),
): boolean {
  if (a === b) {
    return true;
  }

  if (!a || !b) {
    return false;
  }

  if (a.length !== b.length) {
    return false;
  }

  return compareFunc(a, b);
}

export function isArrayDeepEqual<T>(a: T[], b: T[]): boolean {
  if (a === b) {
    return true;
  }

  if (!a || !b) {
    return false;
  }

  if (a.length !== b.length) {
    return false;
  }

  return a.every((item, i) => isDeepEqual(item, b[i]));
}

export function uniqueValues<T>(v: T, i: number, a: T[]) {
  return a.indexOf(v) === i;
}

export function toggleItem<T = any>(
  array: T[],
  item: T,
  toggle?: boolean,
  matcher = (subject: T) => subject === item,
  prepend?: boolean,
): T[] {
  const idx = array.findIndex(matcher);

  if (idx > -1 && !toggle) {
    array.splice(idx, 1);
  } else if (toggle) {
    if (prepend) {
      array.unshift(item);
    } else {
      array.push(item);
    }
  }

  return array;
}

export function removeFromArray<T>(arr: T[], item: T): T[] {
  const idx = arr?.indexOf(item) ?? -1;

  if (idx > -1) {
    arr.splice(idx, 1);
  }

  return arr;
}

export function arrayUnique<T = any>(array: T[]): T[] {
  return Array.from(new Set(array));
}

export function arrayUniqueByProperty<T extends object>(arr: T[], key: keyof T): T[] {
  return arr.filter((item, idx) => idx === arr.findIndex((other) => other[key] === item[key]));
}

export function assertArray<T>(item: T | T[]): T[] {
  return item != null ? (Array.isArray(item) ? item : [item]) : [];
}

export function shuffleArray<T>(array: T[]): T[] {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }

  return array;
}

export function isNotEmptyArray(item: any[]): boolean {
  return Array.isArray(item) && item.length > 0;
}

export function sortByTime<T extends { time: string | number }>(items: T[], prop = 'time'): T[] {
  return items.sort((a, b) => {
    const A = new Date(a[prop] || null).getTime();
    const B = new Date(b[prop] || null).getTime();

    return B - A;
  });
}
