import { fromEvent, merge, Observable, of, ReplaySubject } from 'rxjs';
import { debounceTime, filter, map, shareReplay, startWith, takeUntil } from 'rxjs/operators';

import {
  Directive,
  ElementRef,
  EventEmitter,
  Inject,
  Injectable,
  InjectionToken,
  Input,
  ModuleWithProviders,
  NgModule,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';

import { ViewSize } from '@shared/enums/view-size.enum';
import { LifecycleHooks } from '@shared/services/lifecycle-hooks.service';

export interface BreakPoint {
  mediaQuery: string;
  alias: string;
  suffix?: string;
  overlapping?: boolean;
  priority?: number;
}

export interface ViewPoint {
  min: number;
  max: number;
  alias: ViewSize;
}

const BREAKPOINTS = new InjectionToken<BreakPoint[]>('media-monitor-breakpoints');

@Injectable()
export class MediaMonitor {
  private readonly viewPoints: ViewPoint[] = this.getViewPoints();

  private readonly ro: ResizeObserver = new ResizeObserver((entries) => this.onResize(entries));

  private readonly roListeners = new Map<
    Element,
    {
      subject: ReplaySubject<ViewSize>;
      width: number;
      height: number;
    }
  >();

  readonly media$: Observable<ViewSize> = this.startListeners();

  constructor(
    @Inject(BREAKPOINTS) private bp: BreakPoint[],
    private nz: NgZone,
  ) {
    this.media$.subscribe();
  }

  observeElement(element: ElementRef<HTMLElement>, width: number, height: number): Observable<ViewSize> {
    this.nz.runOutsideAngular(() => {
      if (!this.roListeners.get(element.nativeElement)) {
        this.ro.observe(element.nativeElement);
        this.roListeners.set(element.nativeElement, { subject: new ReplaySubject<ViewSize>(1), width, height });
      }
    });

    return this.roListeners.get(element.nativeElement).subject.asObservable();
  }

  stopObserve(element: ElementRef<HTMLElement>): void {
    this.ro.unobserve(element.nativeElement);
  }

  private onResize(entries: ResizeObserverEntry[]): void {
    entries.forEach(({ target, contentRect }) => {
      const listener = this.roListeners.get(target);

      if (listener) {
        const { width, height, subject } = listener;
        const rectWidth = Math.round(contentRect.width);
        const rectHeight = Math.round(contentRect.height);

        if ((width && rectWidth < width) || (height && rectHeight < height)) {
          this.nz.run(() => subject.next(ViewSize.NotAvailable));
        } else {
          const vp = this.viewPoints.find(({ min, max }) => rectWidth >= min && rectWidth <= max);

          this.nz.run(() => subject.next((vp && vp.alias) || ViewSize.NotAvailable));
        }
      }
    });
  }

  private getViewPoints(): ViewPoint[] {
    return this.bp.map((bp) => this.breakPointToViewPoint(bp));
  }

  private startListeners(): Observable<ViewSize> {
    return this.nz.runOutsideAngular(() =>
      merge(...this.bp.map((bp) => this.breakPointToListener(bp))).pipe(debounceTime(1), shareReplay(1)),
    );
  }

  private breakPointToViewPoint(bp: BreakPoint): ViewPoint {
    const min = bp.mediaQuery.match(/.*min-width:\s+\b(\w+)px\b.*/);
    const max = bp.mediaQuery.match(/.*max-width:\s+\b(\w+)px\b.*/);

    return {
      min: (min && Number.parseInt(min[1], 10)) || 0,
      max: (max && Number.parseInt(max[1], 10)) || Number.MAX_SAFE_INTEGER,
      alias: bp.alias as ViewSize,
    };
  }

  private breakPointToListener(bp: BreakPoint): Observable<ViewSize> {
    if (typeof window?.matchMedia !== 'function') {
      return of(null);
    }

    const query = window.matchMedia(bp.mediaQuery);
    const startEvent = new MediaQueryListEvent('change', { media: query.media, matches: query.matches });

    return fromEvent<MediaQueryListEvent>(query, 'change').pipe(
      startWith(startEvent),
      filter((event) => event.matches),
      map(() => bp.alias as ViewSize),
      shareReplay(1),
    );
  }
}

@Directive({
  selector: `[mediaChange]`,
  providers: [LifecycleHooks],
})
export class MediaChange implements OnInit, OnDestroy {
  @Input()
  useWindow = false;

  @Input()
  widthLimit = 0;

  @Input()
  heightLimit = 0;

  @Output()
  readonly mediaChange = new EventEmitter<ViewSize>();

  constructor(
    private el: ElementRef<HTMLElement>,
    private lh: LifecycleHooks,
    private mm: MediaMonitor,
  ) {}

  ngOnInit(): void {
    const obs$ = this.useWindow ? this.mm.media$ : this.mm.observeElement(this.el, this.widthLimit, this.heightLimit);

    obs$.pipe(takeUntil(this.lh.destroy)).subscribe((size) => this.mediaChange.emit(size));
  }

  ngOnDestroy(): void {
    this.mm.stopObserve(this.el);
  }
}

@NgModule({
  declarations: [MediaChange],
  exports: [MediaChange],
})
export class MediaMonitorModule {
  static forRoot(breakpoints: BreakPoint[]): ModuleWithProviders<MediaMonitorModule> {
    return {
      ngModule: MediaMonitorModule,
      providers: [
        {
          provide: BREAKPOINTS,
          useValue: breakpoints.filter((bp) => ViewSize.RealSizes.includes(bp.alias as ViewSize)),
        },
        MediaMonitor,
      ],
    };
  }
}
