/**
 * Survey rights types.
 *
 * @unstable
 */

export enum Rights {
  NONE = 0,
  VIEW = 1,
  EDIT = 2,
  ADMIN = 3,
  SUPER = 4,
  OWNER = 5,
}

/* eslint-disable @typescript-eslint/no-namespace */
export namespace Rights {
  export function toArray() {
    return Object.keys(Rights)
      .map((key): string => Rights[key])
      .filter((prop: string) => Object.getPrototypeOf(prop) !== Function.prototype);
  }

  export function getName(right: number) {
    switch (right) {
      case Rights.NONE:
        return 'None';
      case Rights.VIEW:
        return 'View';
      case Rights.EDIT:
        return 'Edit';
      case Rights.ADMIN:
        return 'Admin';
      case Rights.SUPER:
        return 'Super';
      case Rights.OWNER:
        return 'Owner';

      default:
        return null;
    }
  }

  export function getRights(teamRights: Rights, userRights: Rights): Rights {
    return teamRights < Rights.ADMIN ? userRights : teamRights;
  }

  export function hasRights(requiredRight: Rights, userRight: Rights | void): boolean {
    return (userRight || 0) >= requiredRight;
  }

  export function objectHasRights(rightsObject: { [key: string]: Rights }, requiredRight: Rights): boolean {
    return Object.values(rightsObject || {}).some((right: Rights) => hasRights(requiredRight, right));
  }
}
