import { StateToken } from '@ngxs/store';

import { TeamData } from '@shared/models/account.model';
import { BillingPlanData } from '@shared/models/billing.model';

export const CONSOLE_ZEFFI_TEAM = 'ZEFFI';

export interface ConsoleStateModel {
  teams?: TeamData[];
  consoleTeam?: TeamData;
  selectedTeam?: TeamData;
  selectedTeamPlan?: BillingPlanData;
  parentTeams?: TeamData[];
}

export const CONSOLE_STATE = new StateToken<ConsoleStateModel>('console');
