import { buffer, debounceTime, filter, mapTo, mergeMap, switchMap, take } from 'rxjs/operators';
import { Observable, of, ReplaySubject, Subject } from 'rxjs';

import { Store } from '@ngxs/store';

import { KeyOf } from '@shared/utilities/typescript.utilities';
import { shareRef } from '@shared/operators/share-ref.operator';

interface StoreAction<T = any> {
  store: Store;
  selector: (...args: any[]) => T;
  action: any;
}

export function assertStoreData<T>(
  store: Store,
  selector: (...args: any[]) => T,
  action: any,
  checker: ((data: T) => boolean) | KeyOf<T> = (data: T): boolean => data != null,
  actualSelector: (...args: any[]) => T = selector,
): Observable<T> {
  const checkData = typeof checker === 'string' ? (data: T) => !!data[checker] : checker;
  const hasData = checkData(store.selectSnapshot<T>(selector));

  return (hasData ? of(void 0) : addToBuffer({ store, action, selector })).pipe(
    switchMap(() => store.select<T>(actualSelector)),
    shareRef(),
  );
}

function addToBuffer<T>(storeAction: StoreAction<T>): Observable<unknown> {
  buffer$.next(storeAction);

  return bufferResponse$.pipe(
    filter((responses) =>
      responses.some(
        (response) => response.selector === storeAction.selector && response.action === storeAction.action,
      ),
    ),
    take(1),
  );
}

const buffer$ = new Subject<StoreAction>();

const bufferResponse$ = new ReplaySubject<StoreAction[]>(1);

buffer$
  .pipe(
    buffer(buffer$.pipe(debounceTime(1))),
    mergeMap((storeActions: StoreAction[]) => {
      if (storeActions.length) {
        const store = storeActions[0].store;
        const actions = storeActions.map(({ action }) => action);

        return store.dispatch(actions).pipe(mapTo(storeActions));
      } else {
        of(storeActions);
      }
    }),
  )
  .subscribe((storeActions) => bufferResponse$.next(storeActions));
