/**
 * Service for controlling error dialogs.
 *
 * @unstable
 */

import { Injectable } from '@angular/core';
import { ComponentType } from '@angular/cdk/portal';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';

import { Store } from '@ngxs/store';

import { Dialog } from '@shared/components/dialog/dialog.component';
import { TeamData } from '@shared/models/account.model';
import { SurveyModel } from '@shared/models/survey.model';
import { IdentityData } from '@shared/models/prefs.model';

@Injectable({
  providedIn: 'root',
})
export class DialogControl {
  public dialogRef: MatDialogRef<Dialog> | null = null;

  constructor(
    readonly dialog: MatDialog,
    readonly store: Store,
  ) {}

  open<T = any, D = any, R = any>(component: ComponentType<T>, data?: any, config?: MatDialogConfig<D>) {
    const dialogRef: MatDialogRef<T, R> = this.dialog.open(component, { data, closeOnNavigation: true, ...config });

    if (data) {
      Object.keys(data).forEach((key: string) => {
        dialogRef.componentInstance[key] = data[key];
      });
    }

    this.dialogRef = dialogRef as any;
    return dialogRef;
  }

  openDialog(
    type: string,
    logo: string,
    title: string,
    label: string,
    header: string,
    content: string,
    actions: string[],
    options?: any,
  ): MatDialogRef<Dialog> {
    const disableClose = !!(options && options.disableClose);

    this.dialog.closeAll();

    this.dialogRef = this.dialog.open<Dialog>(Dialog, {
      disableClose,
    });

    if (type === 'input' || type === 'parent') {
      type = type === 'parent' ? type : 'ask';

      options = options || {};

      options.showInput = true;

      options.inputPlaceholder = content;
    }

    this.dialogRef.componentInstance.type = type;

    logo = logo && logo.indexOf('/') === -1 ? `assets/images/${logo}-placeholder.png` : logo;

    this.dialogRef.componentInstance.logo = logo;

    this.dialogRef.componentInstance.title = title;
    this.dialogRef.componentInstance.label = label;

    this.dialogRef.componentInstance.header = header;

    if (!options || !options.showInput) {
      this.dialogRef.componentInstance.content = content;
    }

    this.dialogRef.componentInstance.action = actions[0];
    this.dialogRef.componentInstance.cancel = actions[1];

    if (options) {
      Object.keys(options).forEach((key: string) => {
        if (key !== 'disableClose') {
          this.dialogRef.componentInstance[key] = options[key];
        }
      });
    }

    return this.dialogRef;
  }

  openAskDialog(title: string, content: string, actions?: string[]): MatDialogRef<Dialog> {
    return this.openDialog(
      'ask',
      '',
      title,
      '',
      '',
      content,
      actions || ['Ok', $localize`:dialog button label@@zef-i18n-00026:Okay`],
    );
  }

  openInfoDialog(title: string, content: string, actions?: string[], autoClose?: number): MatDialogRef<Dialog> {
    return this.openDialog(
      'info',
      '',
      title,
      '',
      '',
      content,
      actions || [$localize`:dialog button label@@zef-i18n-00026:Okay`],
      { autoClose: autoClose || 0, disableClose: false },
    );
  }

  openErrorDialog(title: string, content: string, actions?: string[], autoClose?: number): MatDialogRef<Dialog> {
    return this.openDialog(
      'error',
      '',
      title,
      '',
      '',
      content,
      actions || [$localize`:dialog button label@@zef-i18n-00027:Close`],
      { autoClose: autoClose || 0, disableClose: true },
    );
  }

  openInputDialog(title: string, content: string, actions?: string[], defaultValue?: string): MatDialogRef<Dialog> {
    const options = {
      inputValue: defaultValue || '',
    };

    return this.openDialog(
      'input',
      '',
      title,
      '',
      '',
      content,
      actions || [
        $localize`:dialog button label@@zef-i18n-00028:Save`,
        $localize`:dialog button label@@zef-i18n-00029:Cancel`,
      ],
      options,
    );
  }

  openTeamDialog(
    team: TeamData,
    type: string,
    title: string,
    header: string,
    content: string,
    action: string | string[],
    defaultValue?: string,
  ): MatDialogRef<Dialog> {
    const options = { logoType: 'square', inputValue: defaultValue || '' };

    const actions = Array.isArray(action) ? action : [action, $localize`:dialog button label@@zef-i18n-00029:Cancel`];

    return this.openDialog(type, team?.logo || 'team', title, team?.name || '', header, content, actions, options);
  }

  openUserDialog(
    identity: IdentityData,
    lang: string,
    type: string,
    title: string,
    header: string,
    content: string,
    action: string | string[],
    defaultValue?: string,
  ): MatDialogRef<Dialog> {
    const options = { logoType: 'round', inputValue: defaultValue || '' };

    const actions = Array.isArray(action) ? action : [action, $localize`:dialog button label@@zef-i18n-00029:Cancel`];

    return this.openDialog(
      type,
      identity?.avatar || 'user',
      title,
      identity?.name || '',
      header,
      content,
      actions,
      options,
    );
  }

  openSurveyDialog(
    survey: SurveyModel,
    type: string,
    title: string,
    header: string,
    content: string,
    action: string | string[],
    defaultValue?: string,
  ): MatDialogRef<Dialog> {
    const options = { logoType: 'rectangle', inputValue: defaultValue || '' };

    const basics = survey.survey;
    const design = survey.design;

    const actions = Array.isArray(action) ? action : [action, $localize`:dialog button label@@zef-i18n-00029:Cancel`];

    return this.openDialog(
      type,
      design?.background?.url || 'survey',
      title,
      basics?.name || '',
      header,
      content,
      actions,
      options,
    );
  }

  surveyAccessDeniedDialog(team: TeamData, isAnonymous = false) {
    const title = $localize`:survey access denied dialog title@@zef-i18n-00030:Access Denied`;

    const header = $localize`:survey access denied dialog header@@zef-i18n-00031:You do not have rights to this survey`;

    const content = isAnonymous
      ? $localize`:survey access denied dialog anonymous content@@zef-i18n-00032:This could happen because of change in permissions or if you are not logged in.`
      : $localize`:survey access denied dialog authorized content@@zef-i18n-00033:This could happen because of change in permissions or survey belongs to another organization.`;

    const action = isAnonymous
      ? $localize`:dialog button label@@zef-i18n-00034:Login`
      : $localize`:dialog button label@@zef-i18n-00026:Okay`;

    return this.openTeamDialog(team, 'ask', title, header, content, action);
  }
}
