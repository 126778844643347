import firebase from 'firebase/compat/app';
import { Injectable } from '@angular/core';
import { Commands } from '@shared/enums/commands.enum';
import {
  InviteData,
  TeamAdminSettings,
  TeamData,
  TeamParentSettings,
  TeamType,
  UserData,
} from '@shared/models/account.model';
import { FeatureValue, TeamFeature } from '@shared/models/features.model';
import { IdentityData } from '@shared/models/prefs.model';
import { mapListKeys } from '@shared/operators/map-list-keys.operator';
import { mapObjectKey } from '@shared/operators/map-object-key.operator';
import { shareRef } from '@shared/operators/share-ref.operator';
import { AuditLogService } from '@shared/services/audit-log.service';
import { AuthWrapper } from '@shared/services/auth-wrapper.service';
import { CloudFunctions } from '@shared/services/cloud-functions.service';
import { DatabaseWrapper } from '@shared/services/database-wrapper.service';
import { parseDefaultAdminSettings, parseDefaultParentSettings } from '@shared/utilities/team.utilities';
import { combineLatest, forkJoin, from, Observable, of } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, retry, shareReplay, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AccountManager {
  constructor(
    private db: DatabaseWrapper,
    private cf: CloudFunctions,
    private auth: AuthWrapper,
    private al: AuditLogService,
  ) {}

  listUserInvites(): Observable<InviteData[]> {
    return this.auth.user.pipe(
      map((user) => user?.email),
      distinctUntilChanged(),
      switchMap((email) =>
        email
          ? this.db
              .list<InviteData[]>(`/invites`, (ref) => ref.orderByChild('email').equalTo(email))
              .snapshotChanges()
              .pipe(
                retry(),
                catchError(() => of([])),
                mapListKeys(),
              )
          : of([]),
      ),
    );
  }

  defaultTeamKey(userKey: string) {
    return this.db
      .list(`/users/${userKey}/teams`, (ref) => ref.orderByValue().equalTo(5).limitToFirst(1))
      .snapshotChanges()
      .pipe(map((teams) => teams?.[0]?.key));
  }

  public loadUser(userKey: string): Observable<UserData> {
    return !userKey
      ? of(null)
      : this.db
          .object<UserData>(`/users/${userKey}/`)
          .snapshotChanges()
          .pipe(
            catchError(() => of(null)),
            mapObjectKey(),
            shareReplay({ refCount: true, bufferSize: 1 }),
          );
  }

  public loadTeam(teamKey: string): Observable<TeamData | null> {
    return !teamKey
      ? of(null)
      : combineLatest([
          this.db
            .object<TeamAdminSettings>(`/admin/teams/${teamKey}`)
            .valueChanges()
            .pipe(
              map((settings) => parseDefaultAdminSettings(settings)),
              catchError(() => of(null)),
            ),
          this.db
            .object<TeamData>(`/teams/${teamKey}`)
            .valueChanges()
            .pipe(catchError(() => of(null))),
        ]).pipe(
          switchMap(([adminSettings, team]) => {
            if (adminSettings?.auditLogging) {
              this.al.queueDataLog({ status: 'SUCCESS', access: 'READ', path: `/teams/${teamKey}` });
            }

            return (
              [TeamType.Parent, TeamType.Child].includes(adminSettings?.type)
                ? this.db
                    .object<TeamParentSettings>(`/admin/parent/${adminSettings.childOf || teamKey}`)
                    .valueChanges()
                    .pipe(catchError(() => of(null)))
                : of(null)
            ).pipe(
              map((parentSettings) => ({
                ...team,
                adminSettings,
                parentSettings: parentSettings && parseDefaultParentSettings(parentSettings),
                $key: teamKey,
              })),
            );
          }),
          catchError(() => of(null)),
          shareRef(),
        );
  }

  public loadIdentity(userKey: string, teamKey: string): Observable<IdentityData> {
    return !teamKey || !userKey
      ? of(null)
      : this.db
          .object(`/users/${userKey}/identities/${teamKey}`)
          .snapshotChanges()
          .pipe(
            catchError((error) => of(null).pipe(filter(() => error && error.code === 'PERMISSION_DENIED'))),
            mapObjectKey(),
          );
  }

  public switchTeam(userKey: string, teamKey: string) {
    return !userKey || !teamKey
      ? of(null)
      : forkJoin([
          this.db.object(`/users/${userKey}/identities/${teamKey}/lastLogin`).set(Date.now()),
          this.db.object(`/users/${userKey}/team`).set(teamKey),
        ]);
  }

  public acceptTeamInvite(teamKey: string, inviteKey: string) {
    return this.cf.post(Commands.InviteUser, `${teamKey}/${inviteKey}`);
  }

  public rejectTeamInvite(inviteKey: string) {
    return this.cf.delete(Commands.InviteUser, inviteKey);
  }

  public updateLastActivityTime(userKey: string) {
    this.db.object(`/auth/login/${userKey}/lastActivity`).set(firebase.database.ServerValue.TIMESTAMP);
  }

  updateTeam(teamKey: string, newData: Partial<TeamData>): Observable<unknown> {
    return from(this.db.object<TeamData>(`/teams/${teamKey}`).update(newData));
  }

  updateTeamParentSettings(teamKey: string, update: Partial<TeamParentSettings>): Observable<any> {
    return from(this.db.object(`/admin/parent/${teamKey}`).update(update));
  }

  updateTeamFeature(teamKey: string, feature: TeamFeature, value: FeatureValue) {
    const data = value || null;

    return from(this.db.object(`/admin/teams/${teamKey}/features/${feature}`).set(data));
  }

  toggleAdminTools(userKey: string, value: boolean) {
    return from(this.db.object(`/users/${userKey}/hideAdminTools`).set(value || null));
  }

  updateTeamFeatures(teamKey: string, data: Record<TeamFeature, boolean>) {
    return from(this.db.object(`/admin/teams/${teamKey}/features`).update(data));
  }
}
