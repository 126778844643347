export enum Commands {
  ApiManagement = 'services-apiManagement',
  AutoTranslate = 'services-autoTranslate',
  DeleteEmail = 'services-deleteEmail',
  DeleteSMS = 'services-deleteSms',
  DataPublish = 'services-dataPublish',
  DeleteDraft = 'services-deleteDraft',
  HelpCenterIncrement = 'services-helpCenterIncrement',
  ShareLinks = 'services-shareLinks',
  SassCompiler = 'services-sassCompiler',
  FilesStorage = 'services-filesStorage',
  ImageGallery = 'services-imageGallery',
  CreateTeam = 'services-createTeam',
  JoinTeam = 'services-joinTeam',
  InviteUser = 'services-inviteUser',
  VerifyUser = 'services-verifyUser',
  ManageLocales = 'services-manageLocales',
  ExtractDesign = 'services-extractDesign',
  TeamColor = 'services-teamColor',
  Search = 'services-search',
  Poll = 'services-poll',
  CreateSurvey = 'services-createSurvey',
  CreateTemplate = 'services-createTemplate',
}
