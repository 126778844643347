import { DatePipe } from '@angular/common';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { Params } from '@angular/router';
import { Action, createSelector, Selector, State, StateContext, Store } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { DebounceAction } from '@shared/decorators/debounce-action.decorator';
import { StreamAction } from '@shared/decorators/stream-action.decorator';
import { ViewSize } from '@shared/enums/view-size.enum';
import { ChatPrefs, ContactsPagePrefs, ImportPrefs } from '@shared/models/contact.model';
import { IdentityData, PropertyData, StatesData, SurveyStateData } from '@shared/models/prefs.model';
import { ExportSettings } from '@shared/models/report.model';
import { SurveySharesOrderState } from '@shared/models/survey-shares.model';
import { SurveySearchPrefs } from '@shared/models/survey.model';
import { HelpGuide, HelpSubject } from '@shared/modules/help-center/help-subject.enum';
import { MediaMonitor } from '@shared/modules/media-monitor.module';
import { PrefsManager } from '@shared/services/prefs-manager.service';
import { SourceTypeService } from '@shared/services/source-type.service';
import { SwitchTeam } from '@shared/states/account.actions';
import { AccountState } from '@shared/states/account.state';
import { SignOutWithRedirect } from '@shared/states/auth.actions';
import { PREFS_STATE_TOKEN, PrefsStateModel } from '@shared/states/prefs-state.models';
import { getPrefLanguage } from '@shared/states/prefs-state.utilities';
import {
  GetChatPrefs,
  GetColumns,
  GetContactsPagePrefs,
  GetCountry,
  GetEditorPrefs,
  GetHelpCenter,
  GetIdentities,
  GetIdentity,
  GetImportPrefs,
  GetLanguage,
  GetProperties,
  GetReportExportSettings,
  GetStates,
  GetSurveySearch,
  GetSurveySharesOrderState,
  GetUserFeatures,
  MarkHelpCenterArticle,
  MarkHelpCenterGuide,
  ResetEditorWarnings,
  ScrollEnabled,
  SetCountry,
  SetLanguage,
  TouchEnabled,
  UpdateChatPrefs,
  UpdateContactsPagePrefs,
  UpdateEditorPrefs,
  UpdateIdentity,
  UpdateImportPrefs,
  UpdateMedia,
  UpdateProperties,
  UpdateReportExportSettings,
  UpdateSurveySearchPrefs,
  UpdateSurveySharesOrderState,
  UpdateSurveyShareToggle,
  UpdateUserFeature,
} from '@shared/states/prefs.actions';
import { RouterState } from '@shared/states/router.state';
import { from, fromEvent, merge, Observable, of } from 'rxjs';
import { catchError, distinctUntilChanged, map, skip, switchMapTo, tap } from 'rxjs/operators';
import isMobile = ViewSize.isMobile;
import isDesktop = ViewSize.isDesktop;

const defaultStateModel = {
  country: '',
  language: '',
  locale: '',

  media: null,

  columns: {},
  users: {},
  states: {} as StatesData,
  identity: {} as IdentityData,
  properties: {} as PropertyData,
  surveySearch: {} as SurveySearchPrefs,
  surveySharesOrder: {},
  contactsPage: {} as ContactsPagePrefs,
  import: {} as ImportPrefs,
  chat: {} as ChatPrefs,
  help: {},

  touchEnabled: false,
  scrollEnabled: true,

  reportExportSettings: {} as ExportSettings,
};

@Injectable()
@State<PrefsStateModel>({
  name: PREFS_STATE_TOKEN,
  defaults: defaultStateModel,
})
export class PrefsState {
  static build_lang = 'en';
  constructor(
    @Inject(LOCALE_ID) readonly locale: string,
    private store: Store,
    private pm: PrefsManager,
    private mm: MediaMonitor,
    private st: SourceTypeService,
  ) {
    this.st.registerSourceType('user', {
      action: GetIdentities,
      storeSelector: (key: string) => PrefsState.user(key),
      obs: {},
    });

    if (locale) {
      PrefsState.build_lang = locale.split('-')[0];
    }

    const touchEvent = fromEvent<TouchEvent>(document, 'touchstart');
    const mouseEvent = fromEvent<TouchEvent>(document, 'touchend').pipe(
      switchMapTo(fromEvent<MouseEvent>(document, 'mousedown').pipe(skip(1))),
    );

    merge(touchEvent, mouseEvent)
      .pipe(
        map((event) => event.type === 'touchstart'),
        distinctUntilChanged(),
      )
      .forEach((isEnabled) => {
        this.store.dispatch(new TouchEnabled(isEnabled));
      });

    this.mm.media$.subscribe((changes) => this.store.dispatch(new UpdateMedia(changes)));
  }

  static userSurveyState(key: string) {
    return createSelector(
      [PrefsState],
      (state: PrefsStateModel): SurveyStateData | undefined =>
        state && state.states && state.states.surveyState && state.states.surveyState[key],
    );
  }

  @Selector()
  static lastName(state: PrefsStateModel): string {
    return state.identity.lastName || state.properties.lastName || '';
  }

  @Selector()
  static firstName(state: PrefsStateModel): string {
    return state.identity.firstName || state.properties.firstName || '';
  }

  @Selector()
  static myEmail(state: PrefsStateModel): string {
    return state.properties?.email || state.identity?.email || '';
  }

  @Selector()
  static myPhone(state: PrefsStateModel): string {
    return state.properties?.phone || state.identity?.phone || '';
  }

  @Selector()
  static fullName(state: PrefsStateModel): string {
    const firstName = state.identity.firstName || state.properties.firstName || '';
    const lastName = state.identity.firstName || state.properties.firstName || '';

    return state.identity.name || `${firstName} ${lastName}`;
  }

  @Selector()
  static avatar(state: PrefsStateModel): string {
    return state.identity && state.identity.avatar;
  }

  @Selector()
  static country(state: PrefsStateModel): string {
    return state.country || 'us';
  }

  @Selector([RouterState.queryParams])
  static language(state: PrefsStateModel, params: Params): string {
    return getPrefLanguage(state, params, PrefsState.build_lang);
  }

  @Selector()
  static locale(state: PrefsStateModel): string {
    return state.locale || 'en-US';
  }

  @Selector()
  static newSurveyName(state: PrefsStateModel): string {
    const locale = state.locale || 'en-US';
    const date = new DatePipe(locale).transform(Date.now(), 'shortDate');
    return $localize`New survey` + ' ' + date;
  }

  static user(key: string) {
    return createSelector([PrefsState], (state: PrefsStateModel): IdentityData | undefined => state.users[key]);
  }

  static helpCenterTimestamp(subject: HelpSubject | HelpGuide) {
    return createSelector([PrefsState], ({ help }: PrefsStateModel) => {
      const timestamp = Object.values(help || {})?.find((topic) => topic[subject])?.[subject] || 0;

      return timestamp;
    });
  }

  @Selector()
  static help({ help }: PrefsStateModel) {
    return help || {};
  }

  @Selector()
  static properties(state: PrefsStateModel): PropertyData {
    return state.properties || ({} as PropertyData);
  }

  @Selector()
  static features({ features }: PrefsStateModel) {
    const filtered = !features ? [] : Object.entries(features).map(([key, value]) => ({ key, value }));

    return filtered;
  }

  @Selector()
  static touchEnabled(state: PrefsStateModel): boolean {
    return state.touchEnabled;
  }

  @Selector()
  static scrollEnabled(state: PrefsStateModel): boolean {
    return state.scrollEnabled;
  }

  @Selector()
  static isMobile({ media }: PrefsStateModel): boolean {
    return isMobile(media);
  }

  @Selector()
  static isDesktop({ media }: PrefsStateModel): boolean {
    return isDesktop(media);
  }

  @Selector()
  static isHidden(): boolean {
    return document?.visibilityState === 'hidden';
  }

  @Selector()
  static surveySearch(state: PrefsStateModel): SurveySearchPrefs {
    return state.surveySearch;
  }

  @Selector()
  static contactsPage(state: PrefsStateModel): ContactsPagePrefs {
    return state.contactsPage;
  }

  @Selector()
  static import(state: PrefsStateModel): ImportPrefs {
    return state.import;
  }

  @Selector()
  static helpHidden(state: PrefsStateModel): boolean {
    return !!state.chat?.helpHidden;
  }

  @Selector()
  static hideArchiveWarningDialog({ editor }: PrefsStateModel): boolean {
    return Boolean(editor?.hideArchiveWarningDialog);
  }

  @Selector()
  static hideAiInterviewBanner({ editor }: PrefsStateModel): boolean {
    return Boolean(editor?.hideAiInterviewBanner);
  }

  @Selector()
  static reportExportSettings(state: PrefsStateModel): ExportSettings {
    return state.reportExportSettings;
  }

  @Selector()
  static latestChatDetails(state: PrefsStateModel): { latestChatId: number; latestChatDate: number } {
    return { latestChatId: state?.chat?.latestChatId || null, latestChatDate: state?.chat?.latestChatDate || null };
  }

  static folderOwner(userKey: string) {
    return createSelector([PrefsState], (state: PrefsStateModel): IdentityData | null => {
      const data = state && state.users[userKey];
      return data ? { ...data, uid: userKey } : null;
    });
  }

  static surveySharesOrder(surveyKey: string) {
    return createSelector(
      [PrefsState],
      (state: PrefsStateModel): SurveySharesOrderState => ({
        direction: 'desc',
        sortBy: 'created',
        ...(state.surveySharesOrder[surveyKey] || {}),
      }),
    );
  }

  static getLocale(country: string) {
    switch (country) {
      case 'fi':
        return 'fi-FI';
      default:
        return 'en-US';
    }
  }

  @StreamAction(GetHelpCenter)
  getHelpCenter({ patchState }: StateContext<PrefsStateModel>) {
    return this.pm.getTimestamps().pipe(map((help: any) => patchState({ help })));
  }

  @StreamAction(GetSurveySearch)
  getSurveySearch({ patchState }: StateContext<PrefsStateModel>) {
    return this.pm.loadSurveySearch().pipe(map((surveySearch) => patchState({ surveySearch })));
  }

  @StreamAction(GetSurveySharesOrderState)
  getSurveySharesOrderState(
    { getState, setState }: StateContext<PrefsStateModel>,
    { surveyKey }: GetSurveySharesOrderState,
  ): Observable<any> {
    if (!getState().surveySharesOrder[surveyKey]) {
      setState(patch({ surveySharesOrder: patch({ [surveyKey]: null }) }));
    }

    return this.pm
      .getSurveySharesOrderState(surveyKey)
      .pipe(map((surveySearch) => setState(patch({ surveySharesOrder: patch({ [surveyKey]: surveySearch }) }))));
  }

  @StreamAction(GetContactsPagePrefs)
  getContactsPagePrefs({ patchState }: StateContext<PrefsStateModel>) {
    return this.pm.getContactsPagePrefs().pipe(map((contactsPage) => patchState({ contactsPage })));
  }

  @Action(UpdateContactsPagePrefs)
  updateContactsPagePrefs(
    { setState, getState }: StateContext<PrefsStateModel>,
    { prefs }: UpdateContactsPagePrefs,
  ): Observable<void> {
    setState(patch({ contactsPage: { ...getState().contactsPage, ...prefs } }));

    return this.pm.updateContactsPagePrefs(prefs);
  }

  @StreamAction(GetImportPrefs)
  getImportPrefs({ patchState }: StateContext<PrefsStateModel>) {
    return this.pm.getImportPrefs().pipe(map((importPrefs) => patchState({ import: importPrefs })));
  }

  @StreamAction(GetReportExportSettings)
  getReportExportSettings({ patchState }: StateContext<PrefsStateModel>) {
    return this.pm.loadReportExportSettings().pipe(map((reportExportSettings) => patchState({ reportExportSettings })));
  }

  @Action(UpdateImportPrefs)
  updateImportPrefs(
    { setState, getState }: StateContext<PrefsStateModel>,
    { prefs }: UpdateImportPrefs,
  ): Observable<void> {
    setState(patch({ import: { ...getState().import, ...prefs } }));

    return this.pm.updateImportPrefs(prefs);
  }

  @StreamAction(GetChatPrefs)
  getChatPrefs({ patchState }: StateContext<PrefsStateModel>) {
    return this.pm.getChatPrefs().pipe(map((chatPrefs) => patchState({ chat: chatPrefs })));
  }

  @Action(UpdateChatPrefs)
  updateChatPrefs({ setState, getState }: StateContext<PrefsStateModel>, { prefs }: UpdateChatPrefs) {
    const { chat } = getState();

    setState(patch({ chat: chat ? patch(prefs) : (prefs as ChatPrefs) }));

    return this.pm.updateChatPrefs(prefs);
  }

  @StreamAction(GetEditorPrefs)
  getEditorPrefs({ patchState }: StateContext<PrefsStateModel>) {
    return this.pm.getEditorPrefs().pipe(map((editor) => patchState({ editor })));
  }

  @Action(UpdateEditorPrefs)
  updateEditorPrefs(ctx: StateContext<PrefsStateModel>, { prefs }: UpdateEditorPrefs): Observable<void> {
    return this.pm.updateEditorPrefs(prefs);
  }

  @Action(ResetEditorWarnings)
  resetEditorWarnings(): Observable<void> {
    const resetState = {
      hideArchiveWarningDialog: null,
      hideAiInterviewBanner: null,
    };

    return this.pm.updateEditorPrefs(resetState);
  }

  @StreamAction(GetCountry)
  getCountry({ patchState }: StateContext<PrefsStateModel>): Observable<void> {
    return this.pm.getCountry().pipe(
      map((value) => {
        const country = value || 'us';
        const locale = PrefsState.getLocale(country);
        patchState({ country, locale });
      }),
    );
  }

  @Action(SetCountry)
  setCountry({ patchState }: StateContext<PrefsStateModel>, { country }: SetCountry): Promise<void> {
    const locale = PrefsState.getLocale(country);

    patchState({ country, locale });

    return this.pm.setCountry(country);
  }

  @StreamAction(GetLanguage)
  getLanguage({ patchState }: StateContext<PrefsStateModel>): Observable<PrefsStateModel> {
    const locale = this.locale.split('-')[0];

    return this.pm.getLanguage().pipe(
      tap((language) => localStorage.setItem('zef.locale', window['lang'] || language || locale)),
      map((language) => patchState({ language: window['lang'] || language || locale })),
    );
  }

  @Action(SetLanguage)
  setLanguage({ getState, patchState }: StateContext<PrefsStateModel>, { language }: SetLanguage): Promise<void> {
    const country = getState().country;
    const locale = PrefsState.getLocale(country);
    patchState({ language, locale });

    window['lang'] = language;

    localStorage.setItem('zef.locale', language);

    return this.pm.setLanguage(language);
  }

  @StreamAction(GetStates)
  getStates({ patchState }: StateContext<PrefsStateModel>): Observable<PrefsStateModel> {
    return this.pm.loadStatesData().pipe(map((states) => states && patchState({ states })));
  }

  @StreamAction(GetIdentity)
  getIdentity({ patchState }: StateContext<PrefsStateModel>): Observable<PrefsStateModel> {
    return this.pm.loadIdentity().pipe(map((identity) => identity && patchState({ identity })));
  }

  @StreamAction(GetUserFeatures)
  getFeatures({ patchState }: StateContext<PrefsStateModel>): Observable<PrefsStateModel> {
    return this.pm.loadUserFeatures().pipe(map((features) => features && patchState({ features })));
  }

  @StreamAction(GetProperties)
  getProperties({ patchState }: StateContext<PrefsStateModel>, { user }: GetProperties): Observable<PrefsStateModel> {
    return this.pm.loadProperties(user).pipe(map((properties) => properties && patchState({ properties })));
  }

  @StreamAction(GetColumns)
  getColumns(
    { getState, patchState }: StateContext<PrefsStateModel>,
    { table }: GetColumns,
  ): Observable<PrefsStateModel> {
    return this.pm.loadColumns(table).pipe(
      map((columns) =>
        patchState({
          columns: { ...getState().columns, [table]: columns },
        }),
      ),
    );
  }

  @StreamAction(GetIdentities)
  GetIdentities(
    { getState, patchState }: StateContext<PrefsStateModel>,
    { teamKey, userKeys }: GetIdentities,
  ): Observable<void> {
    userKeys = userKeys || Object.keys(this.store.selectSnapshot(AccountState.team)?.users || {});
    teamKey = teamKey || this.store.selectSnapshot(AccountState.teamKey);

    return merge(
      ...userKeys.map((userKey) =>
        this.pm.loadIdentity(userKey, teamKey).pipe(
          map((identityData) => {
            const users = getState().users || {};
            patchState({ users: { ...users, [userKey]: identityData } });
          }),
        ),
      ),
    );
  }

  @Action(UpdateIdentity)
  updateIdentity({ setState }: StateContext<PrefsStateModel>, { data }: UpdateIdentity) {
    setState(patch({ identity: patch(data) }));

    return from(this.pm.updateIdentity(data, true));
  }

  @Action(UpdateProperties)
  updateProperties({ setState }: StateContext<PrefsStateModel>, { data }: UpdateProperties) {
    setState(patch({ properties: patch(data) }));

    return from(this.pm.updateProperties(data));
  }

  @Action(TouchEnabled)
  touchEnabled({ patchState }: StateContext<PrefsStateModel>, { touchEnabled }: TouchEnabled) {
    patchState({ touchEnabled });
  }

  @Action(ScrollEnabled)
  scrollEnabled({ patchState }: StateContext<PrefsStateModel>, { scrollEnabled }: ScrollEnabled) {
    patchState({ scrollEnabled });
  }

  @Action(UpdateMedia)
  updateMedia({ patchState }: StateContext<PrefsStateModel>, { media }: UpdateMedia) {
    patchState({ media });
  }

  @Action(UpdateSurveySearchPrefs)
  updateSurveySearchParameters(_, { data }: UpdateSurveySearchPrefs) {
    return from(this.pm.updateSurveySearch(data)).pipe(catchError(() => of(null)));
  }

  @Action(UpdateSurveySharesOrderState)
  updateSurveySharesOrderState(_, { surveyKey, data }: UpdateSurveySharesOrderState) {
    return this.pm.updateSurveySharesOrderState(surveyKey, data);
  }

  @Action(UpdateReportExportSettings)
  updateReportExportSettings(_, { reportExportSettings }: UpdateReportExportSettings) {
    return from(this.pm.updateReportExportSettings(reportExportSettings)).pipe(catchError(() => of(null)));
  }

  @Action(UpdateUserFeature)
  updateUserFeature(_, { feature, value }: UpdateUserFeature) {
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);
    const userKey = this.store.selectSnapshot(AccountState.userKey);
    return from(this.pm.updateUserFeature(teamKey, userKey, feature, value)).pipe(catchError(() => of(null)));
  }

  @Action(MarkHelpCenterArticle)
  markHelpCenterArticle(_, { subject, timestamp }: MarkHelpCenterArticle) {
    return this.pm.setHelpCenterSubjectTimestamp('article', subject, timestamp);
  }

  @Action(MarkHelpCenterGuide)
  markHelpCenterGuide(_, { subject, timestamp }: MarkHelpCenterGuide) {
    return this.pm.setHelpCenterSubjectTimestamp('guide', subject, timestamp);
  }

  @DebounceAction(UpdateSurveyShareToggle, { debounceTime: 1000, ignoreProperties: ['show'] })
  updateSurveyInviteToggle(
    { setState, getState }: StateContext<PrefsStateModel>,
    { key, survey, show }: UpdateSurveyShareToggle,
  ) {
    if (!getState().states.surveyState) {
      setState(patch({ states: patch({ surveyState: {} }) }));
    }

    if (!getState().states.surveyState[survey]) {
      setState(patch({ states: patch({ surveyState: patch({ [survey as any]: { shareState: {} } }) }) }));
    }

    if (!getState().states.surveyState[survey].shareState[key]) {
      setState(
        patch({
          states: patch({
            surveyState: patch({ [survey]: patch({ shareState: patch({ [key]: { hidden: false } }) }) }),
          }),
        }),
      );
    }

    if (!getState().states.surveyState[survey].shareState[key].hidden !== show) {
      setState(
        patch({
          states: patch({
            surveyState: patch({
              [survey]: patch({
                shareState: patch({
                  [key]: patch({ hidden: !show }),
                }),
              }),
            }),
          }),
        }),
      );

      return from(this.pm.updateStatesData(getState().states));
    }
  }

  @Action([SignOutWithRedirect, SwitchTeam])
  resetState({ patchState }: StateContext<PrefsStateModel>) {
    patchState({
      columns: {},
      users: {},
      states: {} as StatesData,
      identity: {} as IdentityData,
      properties: {} as PropertyData,
      surveySearch: {} as SurveySearchPrefs,
    });
  }
}
