/**
 * Survey data types.
 *
 * @unstable
 */

export enum Outcomes {
  OUTCOME = 'outcome',
  GOODBYE = 'goodbye',
}

export type OutcomeOptionsScore = 'percent' | 'rank' | 'correct' | null;

export interface OutcomeOptions {
  count: number;
  score: OutcomeOptionsScore;
  hideZeroScoreOutcomes: boolean;
}

export const defaultOutcomeOptions: OutcomeOptions = {
  count: 0,
  score: 'rank',
  hideZeroScoreOutcomes: false,
};
