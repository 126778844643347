import { HttpErrorResponse } from '@angular/common/http';

export class ServerError {
  static readonly type = '[Error] Server Error';

  constructor(readonly error: HttpErrorResponse) {}
}

export class TeamRightsError {
  static readonly type = '[Error] Team Rights Error';

  constructor() {}
}

export class CloudFunctionsError {
  static readonly type = '[Error] Cloud Functions Error';

  constructor() {}
}
