<ng-container *ngIf="sourceItem$ | async as source">
  <div
    *ngIf="!source.icon && source.src"
    class="zef-logo-image"
    [ngClass]="'zef-' + size + ' zef-' + shape"
    [style.background-image]="source.src | encodeURI: 'url' | safeStyle"
  ></div>

  <mat-icon *ngIf="source.icon && source.src" [ngClass]="'zef-icon-' + iconSize" [color]="source.color">
    {{ source.src }}
  </mat-icon>
</ng-container>
