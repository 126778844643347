import { Subject } from 'rxjs';

export const clearStreamAction$ = new Subject<void>();

export function ClearStream() {
  return (target: any, name: string, descriptor: TypedPropertyDescriptor<any>) => {
    const originalMethod: Function = descriptor.value;

    descriptor.value = function (...args: any[]) {
      clearStreamAction$.next();

      return originalMethod.apply(this, args);
    };

    return descriptor;
  };
}
