import { User } from 'firebase/auth';
import { FeatureValue, TeamFeature } from '@shared/models/features.model';

export class InitAuthentication {
  static readonly type = '[Account] Init Authentication';
}

export class Login {
  static readonly type = '[Account] Login';

  readonly user: User;

  constructor(user: User) {
    this.user = { ...user, metadata: { ...user.metadata } };
  }

  toJSON = () => ({ ...this, type: Login.type, user: this.user.displayName });
}

export class Logout {
  static readonly type = '[Account] Logout';

  constructor() {}
}

export class SignIn {
  static readonly type = '[Account] Sign In';

  constructor() {}
}

export class SignOut {
  static readonly type = '[Account] Sign Out';

  constructor(public signIn?: boolean | null) {}
}

export class ChangeScope {
  static readonly type = '[Account] Change Scope';

  constructor(readonly authScope: string) {}
}

export class LinkUserTeam {
  static readonly type = '[Account] Link User Team';

  constructor() {}
}

export class GetTeam {
  static readonly type = '[Account] Get Team';

  constructor(readonly teamKey: string) {}
}

export class CreateTeam {
  static readonly type = '[Account] Create Team';
  constructor(readonly parentTeam?: string) {}
}

export class UpdateTeams {
  static readonly type = '[Account] Update Teams';
}

export class JoinTeam {
  static readonly type = '[Account] Join Team';

  constructor(readonly inviteKey: string) {}
}

export class SetTeamName {
  static readonly type = '[Account] Set Team Name';

  constructor(readonly name: string) {}
}

export class SetTeamLogo {
  static readonly type = '[Account] Set Team Logo';

  constructor(readonly logo: string) {}
}

export class SetTeamColor {
  static readonly type = '[Account] Set Team Color';

  constructor(readonly color: string) {}
}

export class GetUser {
  static readonly type = '[Account] Get User';

  constructor(readonly userKey: string) {}
}

export class SwitchTeam {
  static readonly type = '[Account] Switch Team';

  constructor(readonly teamKey: string) {}
}

export class LeaveTeam {
  static readonly type = '[Account] LeaveTeam';
  constructor(
    readonly userKey: string,
    readonly teamKey: string,
  ) {}
}

export class AcceptTeamInvite {
  static readonly type = '[Account] Accept Team Invite';

  constructor(
    readonly teamKey: string,
    readonly inviteKey: string,
  ) {}
}

export class RejectTeamInvite {
  static readonly type = '[Account] Reject Team Invite';

  constructor(readonly inviteKey: string) {}
}

export class VerifyUser {
  static readonly type = '[Account] Verify User';

  constructor(readonly inviteKey: string) {}
}

export class GetUserInvites {
  static readonly type = '[Account] Get User Invites';

  constructor() {}
}

export class SetVisitorId {
  static readonly type = '[Account] Set Visitor Id';

  constructor(readonly visitorId: string) {}
}

export class AuthDoneFetchAccountData {
  static readonly type = '[Account] Auth Done Fetch';

  constructor(readonly userKey: string) {}
}

export class GetTeamFeatures {
  static readonly type = '[Prefs] Get Team Features';
}

export class UpdateTeamFeature {
  static readonly type = '[Prefs] Update Team Feature';
  constructor(
    readonly feature: TeamFeature,
    readonly value: FeatureValue,
  ) {}
}

export class UpdateAllFeatures {
  static readonly type = '[Prefs] Update All Features';
  constructor(readonly data: Record<TeamFeature, boolean>) {}
}

export class ToggleAdminTools {
  static readonly type = '[Account] Toggle Admin Tools';
  constructor(readonly value: boolean) {}
}
