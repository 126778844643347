import { Observable } from 'rxjs';
import { shareReplay, take } from 'rxjs/operators';

export function shareRef<T>(bufferSize: number = 1) {
  return (source: Observable<T>) => source.pipe(shareReplay({ refCount: true, bufferSize }));
}

export function getLastValue<T>(source: Observable<T>, fallback?: T): T | undefined {
  let value: T = fallback;

  source?.pipe(take(1)).subscribe((v) => (value = v));

  return value;
}
