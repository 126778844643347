/**
 * System-wide constants
 * https://docs.google.com/spreadsheets/d/11wB7PI58MdEvdaWWcLgE7h93Qpgac_I8DzanCL0uLzQ/edit#gid=157423250
 */

export enum Constants {
  MAX_TEAM_NAME_LENGTH = 50,
  DELAY_TOOLTIP = 100,
  QUESTION_AUTO_JUMP = 2000,
  PLAYER_MIN_WIDTH = 320,
  PLAYER_MIN_HEIGHT = 412,
  DEFAULT_SMS_SENDER_NAME = 'Zeffi',
}

export const YOUTUBE_EMBED_ID = $localize`:youtube_embed_id@@youtube_embed_id:yxmKxLmVtZc`;
