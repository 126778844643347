/**
 * Service to call ZEF cloud functions.
 *
 * @unstable
 */

import { environment } from '@env/environment';

import { Observable, Subject, TimeoutError, of, timer, throwError } from 'rxjs';
import { catchError, timeout, retryWhen, mergeMap, mapTo, tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';

import { Store } from '@ngxs/store';

import { Commands } from '@shared/enums/commands.enum';
import { Requests } from '@shared/enums/requests.enum';
import { CloudFunctionsError } from '@shared/states/error.actions';

interface RequestParams {
  body?: any;
  params?: string;

  method: Requests;
  command: Commands;

  contentType: 'text' | 'json' | 'form';
  responseType: 'blob' | 'json';
  observe: 'body' | 'events' | 'response';
  queryParams?: object;
}

@Injectable({
  providedIn: 'root',
})
export class CloudFunctions {
  private readonly tries: number = 3;
  private readonly timeout: number = 60000;
  private readonly retryScaling: number = 3;
  private readonly errorTimeout: number = 5000;

  private requests: { [command in Commands]?: boolean } = {};

  private commandSubjects: {
    [command in Commands]?: Subject<{ method: Requests; response?: any; error?: Error }>;
  } = {};

  constructor(
    private http: HttpClient,
    private store: Store,
  ) {}

  public status(command: string): boolean {
    return !!this.requests[command];
  }

  public get<T = any>(command: Commands, params?: string, queryParams?: RequestParams['queryParams']): Observable<T> {
    return this.request({ command, method: Requests.Get, params, queryParams });
  }

  public put(command: Commands, params?: string, body?: any, contentType?: 'text' | 'json' | 'form'): Observable<any> {
    return this.request({ command, method: Requests.Put, params, body, contentType });
  }

  public post<T = any>(
    command: Commands,
    params?: string,
    body?: any,
    contentType?: 'text' | 'json' | 'form',
    observe: 'body' | 'events' | 'response' = 'body',
  ): Observable<T> {
    return this.request({ command, method: Requests.Post, params, body, contentType, observe });
  }

  public patch<T = any>(command: Commands, params?: string): Observable<T> {
    return this.request({ command, method: Requests.Patch, params });
  }

  public putOnce(command: Commands, ...rest) {
    if (this.requests[command]) {
      return of(null);
    } else {
      this.requests[command] = true;
      return this.put(command, ...rest).pipe(
        tap(() => delete this.requests[command]),
        catchError(() => {
          delete this.requests[command];
          return of(null);
        }),
      );
    }
  }

  public postOnce(command: Commands, ...rest) {
    if (this.requests[command]) {
      return of(null);
    } else {
      this.requests[command] = true;
      return this.post(command, ...rest).pipe(
        tap(() => delete this.requests[command]),
        catchError(() => {
          delete this.requests[command];
          return of(null);
        }),
      );
    }
  }

  public delete<T = any>(command: Commands, params?: string): Observable<T> {
    return this.request({ command, method: Requests.Delete, params });
  }

  public listen(command: Commands): Subject<{ method: Requests; response?: any; error?: any }> {
    return this.getCommandSubject(command);
  }

  public download(command: Commands, params: string, body?: any): Observable<any> {
    return this.request({ command, method: Requests.Post, params, body, responseType: 'blob' });
  }

  private request({
    command,
    method,
    params = '',
    body = null,
    contentType,
    responseType,
    observe,
    queryParams,
  }: Partial<RequestParams>): Observable<any> {
    if (contentType !== 'form') {
      contentType = contentType || (typeof body === 'object' ? 'json' : 'text');
    }

    const cloudUrl = environment.cloudFunctions;
    const url = `${cloudUrl}/${command}` + (params ? `/${params}` : '');

    this.beforeRequest(command as Commands, method as Requests, params);

    const token = this.store.selectSnapshot((state) => state?.auth?.authToken) || '';
    const headers = this.getRequestHeaders(token, contentType as any);

    return this.doRequest(url, method as Requests, queryParams, headers, body, responseType, observe).pipe(
      tap((response: any) => this.afterRequest(command as Commands, method as Requests, response)),
      catchError((error) => this.onCatchError(error, { command, method, params, body } as RequestParams)),
    );
  }

  private doRequest(
    url: string,
    method: Requests,
    queryParams: object,
    headers: HttpHeaders,
    body: any,
    responseType: 'blob' | 'json' = 'json',
    observe: 'body' | 'events' | 'response' = 'body',
  ): Observable<any> {
    return this.http.request(method, url, { body, headers, params: queryParams as any, responseType, observe }).pipe(
      timeout(this.timeout),
      retryWhen((attempts: Observable<any>) =>
        attempts.pipe(
          mergeMap((error: HttpErrorResponse | TimeoutError, i: number): Observable<any> => {
            if (i + 1 >= this.tries || (error instanceof HttpErrorResponse && error.status < 500)) {
              return throwError(error);
            }

            if (!(error instanceof HttpErrorResponse)) {
              return of(true);
            }

            return timer(this.errorTimeout + this.errorTimeout * this.retryScaling * i);
          }),
        ),
      ),
    );
  }

  private onCatchError(error: HttpErrorResponse | TimeoutError, params: RequestParams): Observable<any> {
    this.afterRequest(params.command, params.method, error, true);

    return !(error instanceof HttpErrorResponse) || error.status >= 500
      ? this.store.dispatch(new CloudFunctionsError()).pipe(mapTo(null))
      : throwError(error);
  }

  private afterRequest(command: Commands, method: Requests, response: any, error?: boolean): void {
    if (this.requests[command]) {
      console.log(`Cloud ${method} response:`, command, response);

      this.requests[command] = false;

      this.getCommandSubject(command).next({
        method,
        ...(error ? { error: response } : { response }),
      });
    }
  }

  private beforeRequest(command: Commands, method: Requests, params: string): void {
    console.log(`Cloud ${method} request:`, command, params);

    this.getCommandSubject(command).next({ method });

    this.requests[command] = true;
  }

  private getRequestHeaders(authToken: string, contentType: 'text' | 'json' | 'form'): HttpHeaders {
    const content = {
      text: 'text/plain',
      json: 'application/json',
    };

    const visitorId = this.store.selectSnapshot((state) => state?.account?.visitorId) || '';

    return new HttpHeaders()
      .set('Environment', environment.config)
      .set('Visitor', visitorId)
      .set('Firebase-Authorization', authToken)
      .set('Content-Type', content[contentType]);
  }

  private getCommandSubject(command: Commands): Subject<{ method: Requests; response?: any; error?: Error }> {
    if (!this.commandSubjects[command]) {
      this.commandSubjects[command] = new Subject();
    }

    return this.commandSubjects[command];
  }
}
