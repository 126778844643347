import { IdentityData, PropertyData } from '@shared/models/prefs.model';
import { SurveySearchPrefs } from '@shared/models/survey.model';
import { ChatPrefs, ContactsPagePrefs, ImportPrefs } from '@shared/models/contact.model';
import { SurveySharesOrderState } from '@shared/models/survey-shares.model';
import { EditorPrefs } from '@shared/models/editor.model';
import { ExportSettings } from '@shared/models/report.model';
import { ViewSize } from '@shared/enums/view-size.enum';
import { FeatureValue, UserFeature } from '@shared/models/features.model';
import { HelpGuide, HelpSubject } from '@shared/modules/help-center/help-subject.enum';

export class GetIdentity {
  static readonly type = '[Prefs] Get IDentity';
}

export class GetCountry {
  static readonly type = '[Prefs] Get Country';
}

export class SetCountry {
  static readonly type = '[Prefs] Set Country';

  constructor(readonly country: string | null) {}
}

export class GetLanguage {
  static readonly type = '[Prefs] Get Language';
}

export class SetLanguage {
  static readonly type = '[Prefs] Set Language';

  constructor(readonly language: string | null) {}
}

export class GetStates {
  static readonly type = '[Prefs] Get States';
}

export class GetSurveySearch {
  static readonly type = '[Prefs] Get Survey Search';
}

export class GetChatPrefs {
  static readonly type = '[Prefs] Get Chat';
}

export class GetSurveySharesOrderState {
  static readonly type = '[Prefs] Get Survey Shares Order State';

  constructor(readonly surveyKey: string) {}
}

export class GetContactsPagePrefs {
  static readonly type = '[Prefs] Get Contacts Page Prefs';
}

export class GetImportPrefs {
  static readonly type = '[Prefs] Get Import Prefs';
}

export class GetProperties {
  static readonly type = '[Prefs] Get Properties';

  constructor(readonly user: string | null) {}
}

export class GetIdentities {
  static readonly type = '[Prefs] Get Identities';

  constructor(
    readonly teamKey?: string,
    readonly userKeys?: string[],
  ) {}
}

export class GetColumns {
  static readonly type = '[Prefs] Get Columns';

  constructor(readonly table: string) {}
}

export class GetReportExportSettings {
  static readonly type = '[Prefs] Get Report export settings';
}

export class UpdateIdentity {
  static readonly type = '[Prefs] Update Identity';

  constructor(readonly data: Partial<IdentityData>) {}
}

export class UpdateProperties {
  static readonly type = '[Prefs] Update Properties';

  constructor(readonly data: Partial<PropertyData>) {}
}

export class UpdateContactsPagePrefs {
  static readonly type = '[Prefs] Update Contacts Page Prefs';

  constructor(readonly prefs: Partial<ContactsPagePrefs>) {}
}

export class UpdateImportPrefs {
  static readonly type = '[Prefs] Update Import Prefs';

  constructor(readonly prefs: Partial<ImportPrefs>) {}
}

export class UpdateChatPrefs {
  static readonly type = '[Prefs] Update Chat';

  constructor(readonly prefs: Partial<ChatPrefs>) {}
}

export class GetEditorPrefs {
  static readonly type = '[Prefs] Get Editor Prefs';
}

export class UpdateEditorPrefs {
  static readonly type = '[Prefs] Update Editor Prefs';

  constructor(readonly prefs: Partial<EditorPrefs>) {}
}

export class ResetEditorWarnings {
  static readonly type = '[Prefs] Reset Editor Warnings';
}

export class TouchEnabled {
  static readonly type = '[Prefs] Touch Enabled';

  constructor(readonly touchEnabled: boolean) {}
}

export class ScrollEnabled {
  static readonly type = '[Prefs] Scroll Enabled';

  constructor(readonly scrollEnabled: boolean) {}
}

export class UpdateMedia {
  static readonly type = '[Prefs] Update Media';

  constructor(readonly media: Partial<ViewSize>) {}
}

export class UpdateSurveySearchPrefs {
  static readonly type = '[Prefs] Update Survey Search Prefs';

  constructor(readonly data: Partial<SurveySearchPrefs>) {}
}

export class UpdateSurveySharesOrderState {
  static readonly type = '[Prefs] Update Survey Shares Order State';

  constructor(
    readonly surveyKey: string,
    readonly data: Partial<SurveySharesOrderState>,
  ) {}
}

export class UpdateSurveyShareToggle {
  static readonly type = '[Prefs] Update Survey Share Toggle';

  constructor(
    readonly survey: string,
    readonly key: string,
    readonly show: boolean,
  ) {}
}

export class UpdateReportExportSettings {
  static readonly type = '[Prefs] Update report export settings';

  constructor(readonly reportExportSettings: ExportSettings) {}
}

export class GetUserFeatures {
  static readonly type = '[Prefs] Get User Features';
}

export class UpdateUserFeature {
  static readonly type = '[Prefs] Update User Feature';
  constructor(
    readonly feature: UserFeature,
    readonly value: FeatureValue,
  ) {}
}

export class MarkHelpCenterArticle {
  static readonly type = '[Prefs] Mark Help Center Article';

  constructor(
    readonly subject: HelpSubject,
    readonly timestamp: number = null,
  ) {}
}

export class MarkHelpCenterGuide {
  static readonly type = '[Prefs] Mark Help Center Guide';

  constructor(
    readonly subject: HelpGuide,
    readonly timestamp: number = null,
  ) {}
}

export class GetHelpCenter {
  static readonly type = '[Prefs] Get Help Center';
}
