export function teamLogoPlaceholder(created: number): string {
  const placeholdersCount = 20;
  const index = ((created || 0) % placeholdersCount) + 1;
  return created === 0 ? 'assets/images/team-placeholder.png' : `assets/organization/org-${index}.png`;
}

export const assetsDecoBanners = [
  'assets/decos/banner-random-1.jpg',
  'assets/decos/banner-random-2.jpg',
  'assets/decos/banner-random-3.jpg',
  'assets/decos/banner-random-4.jpg',
  'assets/decos/banner-random-5.jpg',
  'assets/decos/banner-random-6.jpg',
  'assets/decos/banner-random-7.jpg',
  'assets/decos/banner-random-8.jpg',
];

export const storageDecoBanners = assetsDecoBanners.map((src) =>
  src.replace('assets/decos', 'gallery/images/random/banners'),
);
